export const APP_CONSTANTS = {}

export enum JawType {
	UPPER = "upper",
	LOWER = "lower",
	BOTH = "both",
}

export enum ServiceTaskCategory {
	AWAITNG_DOCTOR = "awaitingDoctor",
	AWAITNG_OPERATOR = "awaitingOperator",
}

export enum TaskCategory {
	PROVIDER = "provide",
	OTHERS = "consume",
}

export enum ToothSelectionState {
	AVAILABLE = "available",
	SELECTED = "selected",
	DISABLED = "disabled",
}

export enum ToothPositionNumber {
	UR1 = "UR1",
	UR2 = "UR2",
	UR3 = "UR3",
	UR4 = "UR4",
	UR5 = "UR5",
	UR6 = "UR6",
	UR7 = "UR7",
	UR8 = "UR8",
	UL1 = "UL1",
	UL2 = "UL2",
	UL3 = "UL3",
	UL4 = "UL4",
	UL5 = "UL5",
	UL6 = "UL6",
	UL7 = "UL7",
	UL8 = "UL8",
	LR1 = "LR1",
	LR2 = "LR2",
	LR3 = "LR3",
	LR4 = "LR4",
	LR5 = "LR5",
	LR6 = "LR6",
	LR7 = "LR7",
	LR8 = "LR8",
	LL1 = "LL1",
	LL2 = "LL2",
	LL3 = "LL3",
	LL4 = "LL4",
	LL5 = "LL5",
	LL6 = "LL6",
	LL7 = "LL7",
	LL8 = "LL8",
}

export enum TreatmentFileCategory {
	PATIENT_SCAN = "patient_scan",
	PATIENT_PHOTO = "patient_photo",
	PATIENT_XRAY = "patient_xray",
	PATIENT_CBCT = "patient_cbct",
	REPLACE_ORDER_PHOTO = "replace_order_photo",
}

export enum TreatmentFileSubCategory {
	PATIENT_SCANNER = "scanner_upload",
	PATIENT_SCAN_UPPER = "patient_scan_upper",
	PATIENT_SCAN_LOWER = "patient_scan_lower",
	PATIENT_PHOTO_FACE_SIDE = "patient_photo_face_side",
	PATIENT_PHOTO_FACE_FRONT = "patient_photo_face_front",
	PATIENT_PHOTO_FACE_FRONT_TEETH = "patient_photo_face_front_teeth",
	PATIENT_PHOTO_JAW_UPPER = "patient_photo_jaw_upper",
	PATIENT_PHOTO_JAW_LOWER = "patient_photo_jaw_lower",
	PATIENT_PHOTO_JAW_RIGHT = "patient_photo_jaw_right",
	PATIENT_PHOTO_JAW_FRONT = "patient_photo_jaw_front",
	PATIENT_PHOTO_JAW_LEFT = "patient_photo_jaw_left",
	PATIENT_PHOTO_COMPOSITE = "patient_photo_composite",
	PATIENT_XRAY_TEETH = "patient_xray_teeth",
	PATIENT_XRAY_SKULL = "patient_xray_skull",
	PATIENT_CBCT = "patient_cbct",
	REPLACE_ORDER_PHOTO = "replace_order_photo",
}

export enum TreatmentType {
	UPPER = "upper",
	LOWER = "lower",
	BOTH = "both",
}

export enum PatientGenders {
	MALE = "male",
	FEMALE = "female",
	OTHER = "other",
}
interface Ethnicities {
	[key: string]: string
}
export const PatientEthenicities: Ethnicities = {
	Caucasian: "Caucasian",
	Hispanic: "Hispanic",
	Native_American: "Native American",
	Pacific_Islander: "Pacific Islander",
	African_American: "African American",
	Asian: "Asian",
}

export enum AccessPermissions {
	NONE = "0",
	READ = "1",
	WRITE = "2",
}

export enum TreatmentStates {
	ACTIVE = "active",
	PAUSED = "paused",
	ARCHIVED = "archived",
	COMPLETED = "completed",
}

export enum TaskAssignedRoles {
	DOCTORS = "doc",
	OTHERS = "othr",
}

export const StripeSharedKey =
	"pk_test_51HbC6nBvCsKZ9cV5nuRPqKq0clK5omiieTv1gPYt6tvQTF7cZ2i5sA37Rn47kXqVnLsGpJ5nC66V98IUTbmZQONX00vmt8FyV0"
