import { AddressElement, Elements } from "@stripe/react-stripe-js"
import { loadStripe, StripeAddressElementChangeEvent } from "@stripe/stripe-js"
import React from "react"
import { Button, Form, Modal, Nav, Navbar } from "react-bootstrap"
import {
	useEditAddressMutation,
	useInsertAddressMutation,
} from "../../../services/CelebDoctorAddress.Service"
import { useProfileDialogStore } from "../../../store/profileDialogStore"
import { StripeSharedKey } from "../../../utils/appConstants"
const stripe = loadStripe( StripeSharedKey )
const ProfileAddressAddDialog = () => {
	const profileStore = useProfileDialogStore()
	const editOpenCheck = profileStore.editOpen ? true : false
	const defaultValuesEdit = profileStore.editOpen
	const [ ready, setReady ] = React.useState( false )
	const { mutate: onSaveAddress } = useInsertAddressMutation()
	const { mutate: onEditAddress } = useEditAddressMutation()
	const [ address, setAddress ] = React.useState( {
		id: "",
		name: "",
		addressLine1: "",
		addressLine2: "",
		city: "",
		state: "",
		country: "",
		pincode: "",
	} )
	const onSubmit = () => {
		const tempObj = {
			...address,
			id: crypto.randomUUID(),
		}
		if ( editOpenCheck ) {
			onEditAddress( address )
			closeModal()
		} else {
			onSaveAddress( tempObj )
			closeModal()
		}
	}
	const closeModal = () => {
		profileStore.setCreateOpen( false )
		profileStore.setEditOpen( null )
		setReady( false )
	}
	const onAddressChange = ( e: StripeAddressElementChangeEvent ) => {
		const tempObj = {
			id: defaultValuesEdit?.id || "",
			name: e.value.name,
			addressLine1: e.value.address.line1,
			addressLine2: e.value.address.line2 + "",
			city: e.value.address.city,
			state: e.value.address.state,
			country: e.value.address.country,
			pincode: e.value.address.postal_code,
		}
		if ( e.complete ) {
			setAddress( tempObj )
			setReady( true )
		}
	}

	return (
		<React.Fragment>
			<Modal
				show={profileStore.createOpen || editOpenCheck}
				backdrop="static"
				keyboard={false}
				size="lg"
				onHide={closeModal}
			>
				<Navbar style={{ backgroundColor: "#d9d9d9" }} as={Modal.Header} closeButton>
					<Nav>
						<Nav.Link className="nav-link-opposite mt-1" active={true}>
							{editOpenCheck ? "Edit" : "Add"} Address
						</Nav.Link>
					</Nav>
				</Navbar>

				<Modal.Body>
					<Form
						onSubmit={( e ) => {
							e.preventDefault()
							onSubmit()
						}}
					>
						<Elements stripe={stripe}>
							<AddressElement
								onChange={( e ) => onAddressChange( e )}
								options={{
									defaultValues: {
										address: {
											country: defaultValuesEdit?.country || "",
											city: defaultValuesEdit?.city || "",
											line1: defaultValuesEdit?.addressLine1,
											line2: defaultValuesEdit?.addressLine2,
											state: defaultValuesEdit?.state,
											postal_code: defaultValuesEdit?.pincode,
										},
										name: defaultValuesEdit?.name,
									},
									display: {
										name: "organization",
									},
									mode: "billing",
									blockPoBox: true,
								}}
							/>
						</Elements>
						{ready && (
							<Button className="my-2" type="submit">
								{editOpenCheck ? "Edit" : "Add"} Address
							</Button>
						)}
					</Form>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default ProfileAddressAddDialog
