import { useMutation, useSuspenseQuery } from "@tanstack/react-query"
import { z } from "zod"
import { queryClient } from "../providers/QueryProvider"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"
export class CelebDoctorAddressService {
	static _instance: CelebDoctorAddressService = new CelebDoctorAddressService()

	private constructor() {
		CelebDoctorAddressService._instance = this
	}

	static getInstance(): CelebDoctorAddressService {
		return CelebDoctorAddressService._instance
	}

	async add( requestPayload: AddressSchemaType ) {
		return await CelebRequestGeneratorService.processPostRequest(
			constants.DOCTOR_ADDRESS,
			requestPayload,
		)
	}

	async edit( id: String, requestPayload: AddressSchemaType ) {
		return await CelebRequestGeneratorService.processPutRequest(
			constants.DOCTOR_ADDRESS + "/" + id,
			requestPayload,
		)
	}

	async getMyAddresses() {
		return await CelebRequestGeneratorService.processGetRequest( constants.DOCTOR_ADDRESS + "/me" )
	}

	async getDoctorAddresses( doctorId: any ) {
		return await CelebRequestGeneratorService.processGetRequest(
			`${constants.DOCTOR_ADDRESS}${constants.DOCTOR}/${doctorId}`,
		)
	}

	async deleteAddress( id: string ) {
		return await CelebRequestGeneratorService.processDeleteRequest(
			`${constants.DOCTOR_ADDRESS}/${id}`,
		)
	}
}

export const listAddressQueryKeyFn = () =>
	[ "listOfAddress", constants.DOCTOR_ADDRESS ].filter( Boolean )

export const useListAddressQuery = () =>
	useSuspenseQuery( {
		queryKey: [ "listOfAddress", constants.DOCTOR_ADDRESS ],
		queryFn: () =>
			CelebDoctorAddressService.getInstance()
				.getMyAddresses()
				.then( ( res ) => ( { data: res.data.data } ) )
				.then( ( { data } ) => {
					return {
						Address: data,
					}
				} ),
	} )

export const useGetDoctorAddressesMutate = () =>
	useMutation( {
		mutationFn: ( doctorId: string ) =>
			CelebDoctorAddressService.getInstance()
				.getDoctorAddresses( doctorId )
				.then( ( data ) => data.data.data )
				.then( ( dataValue ) => dataValue ),
		onSuccess: ( data ) => {
			return data
		},
	} )

export const useDeleteAddressMutation = () =>
	useMutation( {
		mutationFn: ( payload: string ) => CelebDoctorAddressService.getInstance().deleteAddress( payload ),
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listAddressQueryKeyFn(), exact: false } )
		},
	} )
export const useInsertAddressMutation = () =>
	useMutation( {
		mutationFn: ( payload: AddressSchemaType ) =>
			CelebDoctorAddressService.getInstance().add( payload ),
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listAddressQueryKeyFn(), exact: false } )
		},
	} )
export const useEditAddressMutation = () =>
	useMutation( {
		mutationFn: ( payload: AddressSchemaType ) =>
			CelebDoctorAddressService.getInstance().edit( payload.id, payload ),
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listAddressQueryKeyFn(), exact: false } )
		},
	} )
export const AddressSchema = z.object( {
	id: z.string().default( "1" ),
	name: z.string().trim().min( 3, { message: "Please enter valid address Name" } ),
	addressLine1: z.string().trim().min( 3, { message: "Please enter valid address" } ),
	addressLine2: z.string().trim().optional(),
	city: z.string().trim().min( 2, { message: "Please enter valid city" } ),
	state: z.string().trim().min( 2, { message: "Please enter valid state" } ),
	country: z.string().trim().min( 2, { message: "Please enter valid country" } ),
	pincode: z.string().trim().min( 2, { message: "Please enter valid Pincode" } ),
} )

export type AddressSchemaType = z.infer<typeof AddressSchema>
