import fileIcon from "../../../assets/img/file.png"
import placeHolderFaceFront from "../../../assets/img/placeholder_face_front.png"
import placeHolderFaceSide from "../../../assets/img/placeholder_face_side.png"
import placeHolderJawFront from "../../../assets/img/placeholder_jaw_front.png"
import placeHolderJawLower from "../../../assets/img/placeholder_jaw_lower.png"
import placeHolderJawSideLeft from "../../../assets/img/placeholder_jaw_side_left.png"
import placeHolderJawSideRight from "../../../assets/img/placeholder_jaw_side_right.png"
import placeHolderJawUpper from "../../../assets/img/placeholder_jaw_upper.png"
import placeHolderXraySkull from "../../../assets/img/xray_skull.png"
import placeHolderXrayTeeth from "../../../assets/img/xray_teeth.png"
export let photosLibrary: { [key: string]: any } = {
	patient_photo_face_side: placeHolderFaceSide,
	patient_photo_face_front: placeHolderFaceFront,
	patient_photo_face_front_teeth: placeHolderFaceFront,
	patient_photo_jaw_upper: placeHolderJawUpper,
	patient_photo_jaw_lower: placeHolderJawLower,
	patient_photo_jaw_right: placeHolderJawSideRight,
	patient_photo_jaw_front: placeHolderJawFront,
	patient_photo_jaw_left: placeHolderJawSideLeft,
	patient_cbct: fileIcon,
	patient_scan_upper: fileIcon,
	patient_scan_lower: fileIcon,
	patient_xray_teeth: placeHolderXrayTeeth,
	patient_xray_skull: placeHolderXraySkull,
}

export const photosLibraryReset = () => {
	photosLibrary = {
		patient_photo_face_side: placeHolderFaceSide,
		patient_photo_face_front: placeHolderFaceFront,
		patient_photo_face_front_teeth: placeHolderFaceFront,
		patient_photo_jaw_upper: placeHolderJawUpper,
		patient_photo_jaw_lower: placeHolderJawLower,
		patient_photo_jaw_right: placeHolderJawSideRight,
		patient_photo_jaw_front: placeHolderJawFront,
		patient_photo_jaw_left: placeHolderJawSideLeft,
		patient_cbct: fileIcon,
		patient_scan_upper: fileIcon,
		patient_scan_lower: fileIcon,
		patient_xray_teeth: placeHolderXrayTeeth,
		patient_xray_skull: placeHolderXraySkull,
	}
}
