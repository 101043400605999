import React from "react"
import { Modal, ProgressBar } from "react-bootstrap"
import { userSessionStore } from "../../store/userSessionStore"

const ProgressBarLoader = () => {
	const { progressLoaderObj } = userSessionStore()

	return (
		<React.Fragment>
			<Modal show={progressLoaderObj.show} size="lg">
				<Modal.Body>
					<ProgressBar max={progressLoaderObj.max} animated now={progressLoaderObj.now} />
					<p>{progressLoaderObj.message}</p>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default ProgressBarLoader
