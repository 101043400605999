import { useEffect, useState } from "react"
import "./loadingOverlay.css"

const LoadingOverlay = ( { isLoading }: { isLoading?: boolean } ) => {
	const [ loader, setloader ] = useState( !isLoading )
	useEffect( () => {
		setTimeout( () => {
			setloader( !loader )
		}, 100 )
	}, [] )

	return loader ? (
		<div className="loading-overlay">
			<div className="spinner"></div>
		</div>
	) : null
}

export const OverlaySpinner = () => (
	<div className="loading-overlay">
		<div className="spinner"></div>
	</div>
)

export default LoadingOverlay
