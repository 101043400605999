import { useMutation, useSuspenseQuery } from "@tanstack/react-query"
import axios from "axios"
import { z } from "zod"
import { queryClient } from "../providers/QueryProvider"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"

export class CelebServiceService {
	static _instance: CelebServiceService = new CelebServiceService()

	private constructor() {
		CelebServiceService._instance = this
	}

	static getInstance(): CelebServiceService {
		return CelebServiceService._instance
	}

	async listServices( offset?: number, search?: string ) {
		let queryParam = `?offset=${offset ?? 0}`
		if ( search && search.length > 0 ) {
			queryParam += `&search=${search}`
		}

		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.SERVICE}${queryParam}`
		return await axios.get( requestUrl )
	}

	async listMyServices() {
		const requestUrl = `${constants.SERVICE}/me?categories=true`
		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}

	async createService( requestPayload: any ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.SERVICE}`
		return await axios.post( requestUrl, requestPayload )
	}

	async fetchDetails( serviceId: String ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.SERVICE}/${serviceId}`
		return await axios.get( requestUrl )
	}

	async fetchCategoryDetails( serviceId: String ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.TASK_CATEGORIES}/${serviceId}`
		return await axios.get( requestUrl )
	}

	async updateService( serviceId: EditId, requestPayload: any ) {
		if ( serviceId == null ) return
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.SERVICE}/${serviceId}`
		return await axios.put( requestUrl, requestPayload )
	}

	async deleteservice( id: string ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.SERVICE}/${id}`
		return await axios.delete( requestUrl )
	}

	async loadUserServices( doctorId: string ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.USER_SERVICE_PRICINGS}${constants.DOCTOR}/${doctorId}`
		return await axios.get( requestUrl )
	}
}

export const listServiceQueryKeyFn = ( props?: UseListServiceQueryProps ) =>
	[ "list", props ].filter( Boolean )

export const useListServicesQuery = ( props?: UseListServiceQueryProps ) =>
	useSuspenseQuery( {
		queryKey: [ "list", constants.SERVICE, props ],
		queryFn: () =>
			CelebServiceService.getInstance()
				.listServices( props?.pageOffset, props?.query )
				.then( ( res ) => ( { data: res.data.data, total: res.headers[ "x-total-count" ] } ) )
				.then( ( { data, total } ) => {
					return {
						services: ServiceSchema.array().parse( data ),
						total: total,
					}
				} ),
	} )

export const useListAllServicesQuery = () =>
	useSuspenseQuery( {
		queryKey: [ "list", constants.SERVICE ],
		queryFn: () =>
			CelebServiceService.getInstance()
				.listMyServices()
				.then( ( res ) => ( { data: res.data.data, total: res.headers[ "x-total-count" ] } ) )
				.then( ( { data, total } ) => {
					return {
						services: data,
						total: total,
					}
				} ),
	} )

export const useServicefetchDetails = ( serviceId: string ) =>
	useSuspenseQuery( {
		queryKey: [ "list", constants.SERVICE, serviceId ],
		queryFn: () =>
			CelebServiceService.getInstance()
				.fetchDetails( serviceId )
				.then( ( res ) => ( { data: res.data.data } ) )
				.then( ( { data } ) => {
					return {
						services: data,
						tasks: data.tasks,
					}
				} ),
	} )

export const useCategoryfetchDetails = ( serviceId: string ) =>
	useSuspenseQuery( {
		queryKey: [ "listCategoryProvider", constants.TASK_CATEGORIES, serviceId ],
		queryFn: () =>
			CelebServiceService.getInstance()
				.fetchCategoryDetails( serviceId )
				.then( ( res ) => ( { data: res.data.data } ) )
				.then( ( { data } ) => data ),
	} )

export const useCategoryfetchDetailsMutate = () =>
	useMutation( {
		mutationFn: ( serviceId: string ) => {
			return CelebServiceService.getInstance()
				.fetchDetails( serviceId )
				.then( ( res ) => ( { data: res.data.data } ) )
				.then( ( { data } ) => {
					return {
						services: data,
						tasks: data.tasks,
					}
				} )
		},
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listServiceQueryKeyFn(), exact: false } )
		},
	} )

export const useListUserServicePricesQuery = ( doctorId: string ) =>
	useSuspenseQuery( {
		queryKey: [ "list", constants.USER_SERVICE_PRICINGS, constants.DOCTOR, doctorId ],
		queryFn: () =>
			CelebServiceService.getInstance()
				.loadUserServices( doctorId )
				.then( ( res ) => res.data.data )
				.then( ( data ) => data ),
	} )

export const useCreateServiceMutation = () =>
	useMutation( {
		mutationFn: ( payload: any ) => {
			return CelebServiceService.getInstance().createService( payload )
		},
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listServiceQueryKeyFn(), exact: false } )
		},
	} )

export const useEditServiceMutation = () =>
	useMutation( {
		mutationFn: ( serviceUpdate: { serviceId: EditId; requestPayload: any } ) =>
			CelebServiceService.getInstance().updateService(
				serviceUpdate.serviceId,
				serviceUpdate.requestPayload,
			),
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listServiceQueryKeyFn(), exact: false } )
		},
	} )

export const useDeleteServiceMutation = () =>
	useMutation( {
		mutationFn: ( payload: string ) => CelebServiceService.getInstance().deleteservice( payload ),
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listServiceQueryKeyFn(), exact: false } )
		},
	} )

export const ServiceSchema = z.object( {
	id: z.string(),
	entityId: z.number(),
	name: z.string(),
	description: z.string().nullable(),
	logo: z.string().nullable(),
	email: z.string().email().nullable(),
	contactNo: z.string().nullable(),
	isActive: z.boolean(),
	deleted: z.boolean(),
	deletedBy: z.string().datetime().nullable(),
	deletedAt: z.string().datetime().nullable(),
	createdAt: z.string().datetime(),
	updatedAt: z.string().datetime(),
} )

export const UserServiceSchema = z.object( {
	id: z.string(),
	priceUpper: z.number(),
	priceLower: z.number(),
	priceBoth: z.number(),
	doctorId: z.string(),
	serviceId: z.string(),
	createdAt: z.string().datetime(),
	updatedAt: z.string().datetime(),
	service: z.object( {
		name: z.string(),
		id: z.string(),
		entityId: z.number(),
	} ),
} )

export const ServiceTaskForm = z.object( {
	id: z.string().optional(),
	name: z.string().trim().min( 1, { message: "required task name" } ),
	providerCategoryId: z.string().min( 1, { message: "Provider category is mandatory" } ),
	consumerCategoryId: z.string().min( 1, { message: "Consumer category is mandatory" } ),
	writeNote: z.boolean(),
	uploadFile: z.boolean(),
	checkmark: z.boolean(),
	approvalTask: z.boolean().optional().default( false ),
	previousTasks: z.array( z.any() ).default( [] ),
	rollBackToTask: z.array( z.any() ).default( [] ),
	paymentStage: z.boolean().default( false ),
	replaceOrderTask: z.boolean().default( false ),
	daysToComplete: z.number().default( 1 ).nullable().optional(),
	isActive: z.boolean().optional().nullable(),
	isNew: z.boolean().optional().nullable(),
	serviceId: z.string().optional().nullable(),
} )

export const ServiceSchemaForm = z.object( {
	name: z.string().trim().min( 1, { message: "required service name" } ),
	logo: z.any().nullable(),
	status: z.boolean().default( true ),
	tasks: z
		.object( {
			id: z.string().min( 2, { message: "Task id not assigned" } ),
			name: z.string(),
			providerCategoryId: z.string(),
			consumerCategoryId: z.string(),
			writeNote: z.boolean(),
			uploadFile: z.boolean(),
			checkmark: z.boolean(),
			previousTasks: z.array( z.any() ).default( [] ),
			rollBackToTask: z.array( z.any() ).default( [] ),
			paymentStage: z.boolean().optional().default( false ),
			approvalTask: z.boolean().optional().default( false ),
			replaceOrderTask: z.boolean().optional().default( false ),
			daysToComplete: z.number().default( 1 ).nullable().optional(),
			isActive: z.boolean().optional().nullable(),
			isNew: z.boolean().optional().nullable(),
			serviceId: z.string().optional().nullable(),
		} )
		.array()
		.min( 1, { message: "please add atleast one task" } ),
} )

export type UseListServiceQueryProps = {
	pageOffset?: number
	query?: string
	limit?: number
}

export type UserServiceSchemaType = z.infer<typeof UserServiceSchema>
type EditId = string | null

export interface CategoriesResponseType {
	id: string
	entityId: number
	name: string
	description: any
	logo: any
	email: any
	contactNo: any
	isActive: boolean
	deleted: boolean
	deletedAt: any
	deletedBy: any
	createdAt: string
	updatedAt: string
	categories: CategoriesType[]
}

export interface CategoriesType {
	id: string
	name: string
	type: string
	deleted: boolean
	deletedBy: any
	deletedOn: any
	serviceId: string
	createdAt: string
	updatedAt: string
}
