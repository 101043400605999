import React from "react"
import { CloseButton, Col, Form, Row } from "react-bootstrap"
import { getFileReadUrlQuery } from "../../../services/CelebMedicalFile.Service"
import { getPatientTreatmentFilesQuery } from "../../../services/CelebTreatmentFiles.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import { photosLibrary } from "../editPatients-v2/defaultFileShowCase"
import { fileResult } from "./PatientPhotos.tab"

const PatientCbctTab = () => {
	const [ cbct, setCbct ] = React.useState<"noCbct" | "cbct">( "noCbct" )
	return (
		<React.Fragment>
			<div className="d-flex gap-5">
				<Form.Check
					reverse
					type="radio"
					defaultChecked
					label="No Cbct"
					name="celebrace"
					onChange={() => setCbct( "noCbct" )}
				/>
				<Form.Check
					reverse
					type="radio"
					label="Upload CBCT"
					name="celebrace"
					onChange={() => setCbct( "cbct" )}
				/>
			</div>
			{cbct == "noCbct" ? <></> : <CbctUpload />}
		</React.Fragment>
	)
}

const CbctUpload = () => {
	const { cbctDocumentJson, setCbctDocument, replaceOpen } = usePatientDialogStore()

	const { data: defaultTreatmentFiles } = getPatientTreatmentFilesQuery( replaceOpen )

	defaultTreatmentFiles.forEach( ( file: fileResult ) => {
		if ( file.fileCategory == "patient_cbct" ) {
			const imageUri = getFileReadUrlQuery( file.fileUri )
			photosLibrary[ file.fileSubCategory ] = imageUri.data
		}
	} )
	const fileImageSaver = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		e.preventDefault()
		if ( e.currentTarget.files ) {
			setCbctDocument( "patient_cbct", e.currentTarget.files?.[ 0 ] )
		}
	}
	return (
		<Form>
			<Row className="my-4">
				<Col>
					<div className="d-flex gap-4">
						<label htmlFor="file-input-upper" className="d-flex">
							<img
								src={
									cbctDocumentJson.patient_cbct
										? URL.createObjectURL( cbctDocumentJson.patient_cbct )
										: photosLibrary[ "patient_cbct" ]
								}
								className="upload-file-icon"
							/>
							<p>
								CBCT Scan<span className="text-danger">*</span>
							</p>
						</label>
						<span className="d-flex">
							<p>{cbctDocumentJson.patient_cbct ? cbctDocumentJson.patient_cbct.name : ""}</p>
							{cbctDocumentJson.patient_cbct ? (
								<CloseButton
									className="ms-2"
									onClick={() => setCbctDocument( "patient_cbct", null )}
								/>
							) : (
								""
							)}
						</span>
						<input hidden id="file-input-upper" type="file" onChange={fileImageSaver} />
					</div>
				</Col>
			</Row>
		</Form>
	)
}

export default PatientCbctTab
