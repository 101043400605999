import React from "react"
import { Form } from "react-bootstrap"
import { get, UseFormReturn } from "react-hook-form"
import Moment from "react-moment"

// Define the props for DatePicker
type DatePickerProps = {
	methods: UseFormReturn<any> // Use the correct type for the form methods
	name: string
	required?: boolean
	label: string
}

const DatePicker = ( { methods, name, label, required }: DatePickerProps ) => {
	console.log( "current Dob", methods.watch( name ))
	React.useEffect( () => {
		const currentDate = methods.watch( name );
		if ( currentDate ) {
			methods.setValue( name, new Date( currentDate ).toISOString().split( 'T' )[ 0 ] );
		}else{
			const date = new Date();
			const pastDate = new Date( date.getFullYear() - 12, date.getMonth(), date.getDate() );
			methods.setValue( name, pastDate.toISOString().split( 'T' )[ 0 ] );
		  }
	}, [ name ] )
	return (
		<>
			<Form.Label>
				{label}
				{required && <span style={{ color: "red" }}>*</span>}
			</Form.Label>
			<span className="d-flex position-relative">
				<label className="form-control z-2" htmlFor="datePick">
					<Moment format="MM-DD-YYYY" toNow date={methods.watch( name )} />
				</label>
				<input
					className="form-control position-absolute"
					style={{ width: "0px", border: "none", zIndex: 1, opacity: 0 }}
					type="date"
					id="datePick"
					onClick={( e ) => {
						e.preventDefault()
						e.currentTarget.showPicker()
					}}
					value={methods.watch( name ) || ''}
					onChange={( e ) => methods.setValue( name, e.currentTarget.value )}
				/>
				{required && (
					<p className="text-danger">
						{required && methods.formState.errors[ name ] && (
							<p className="text-danger">{get( methods.formState.errors, name )?.message}</p>
						)}
					</p>
				)}
			</span>
		</>
	)
}

export default DatePicker
