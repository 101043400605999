export const GENERIC_PERMISSIONS = {
	First_Name: "FN1",
	Last_Name: "LN1",
	Birth_Date: "BD1",
	Ethnicity: "ETH1",
	Menu_Accounts: "MENU_ACC",
	Menu_Services: "MENU_SER",
	Menu_Financials: "MENU_FIN",
	Menu_Data: "MENU_DAT",
	Account_Type_List: "ACCTYP_LIST",
	Account_Type_Create: "ACCTYP_CREATE",
	Account_Type_Edit: "ACCTYP_EDIT",
	Accounts_List: "ACCOUNT_LIST",
	Accounts_Create: "ACCOUNT_CREATE",
	Accounts_Edit: "ACCOUNT_EDIT",
	Service_List: "SERVICE_LIST",
	Service_Create: "SERVICE_CREATE",
	Service_Edit: "SERVICE_EDIT",
	Patient_List: "PATIENT_LIST",
	Patient_Create: "PATIENT_CREATE",
	Task_Completion: "TASK_COMPLETION",
	Task_Select: "TASK_SELECT",
	Patient_Edit: "PATIENT_EDIT",
	Menu_Profile: "MENU_PROFILE",
	Office_Tab: "OFFICE_TAB",
	Pricing_Tab: "PRICING_TAB",
	Invoicing_Tab: "INVOICING_TAB",
	Payment_Tab: "PAYMENT_TAB",
	Add_Office: "ADD_OFFICE",
	Office_List: "OFFICE_LIST",
	Office_Edit: "OFFICE_EDIT",
	Pricing_List: "PRICING_LIST",
	Add_Payment_Method: "ADD_PAYMENT_METHOD",
	View_TP: "VIEW_TP",
	Patient_Replace: "PATIENT_REPLACE",
	Patient_Pause: "PATIENT_PAUSE",
	Patient_Transfer: "PATIENT_TRANSFER",
	Patient_Archive: "PATIENT_ARCHIVE",
	ProfileInfo_Update: "PROFILE_INFO_UPDATE",
	ProfilePhoto_Update: "PROFILE_PHOTO_UPDATE",
	Email_Notification: "EMAIL_NOTIFICATION",
	Treatment_Files: "TREATMENT_FILES",
	Treatment_Photo_Composite: "TREATMENT_PHOTO_COMPOSITE",
	Treatment_Photo_Individual: "TREATMENT_PHOTO_INDIVIDUAL",
	Task_Complete_Btn: "TASK_COMPLETE_BTN",
	Task_Complete_CheckBtn: "TASK_COMPLETE_CHCKBTN",
	Task_Complete_Note: "TASK_COMPLETE_NOTE",
	Task_Complete_Rollback: "TASK_COMPLETE_ROLLBACK",
	Task_Complete_uploadFile: "TASK_COMPLETE_UPLOAD",
	Treatment_Dropdown: "TREATMENT_DROPDOWN",
} as const

type GenericPermissionsKeys = keyof typeof GENERIC_PERMISSIONS

export type GENERIC_PERMISSIONS_TYPE = {
	[K in GenericPermissionsKeys]: number | string
}
