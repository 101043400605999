import { faArrowCircleRight, faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "@xyflow/react/dist/style.css"
import React from "react"
import { Card } from "react-bootstrap"
import { useParams } from "react-router"
import LoadingOverlay from "../../assets/styles/components/loader/loadingOverlay"
import { useServicefetchDetails } from "../../services/CelebService.Service"
import { useSidebarStore } from "../../store/sideBarStore"
import "./graph.css"
import { SortTasksAccordingly, TASKS_TYPE } from "./nodes"
import { userSessionStore } from "../../store/userSessionStore"
import { CheckTimelinePermissions } from "../permissions/CheckPermision"
type taskObject = {
	id: string
	name: string
	completedBy: string
	completedOn: string
	previousTasks: string[]
	animated: boolean
	isCompleted: boolean
	assignedTo: string
}

export default function AppGraph() {
	const params = useParams()
	const { data: serviceDetails } = useServicefetchDetails( params.id ? params.id : "" )
	const { timelineData } = useSidebarStore()
	const sortedtTasks = SortTasksAccordingly( serviceDetails.tasks )
	const session = userSessionStore();
	const mapTasksToTaskObjects = () => {
		const ObjectArray: taskObject[] = []
		let completedTill = true
		for ( let index = 0; index < sortedtTasks.length; index++ ) {
			const task: TASKS_TYPE = sortedtTasks[ index ]
			if ( task.id === timelineData?.taskId ) {
				ObjectArray[ index ] = {
					id: task.id,
					name: task.name,
					completedBy: task.taskAssignedRole,
					completedOn: task.updatedAt,
					previousTasks: task.previousTasks,
					animated: true,
					isCompleted: completedTill,
					assignedTo: task.taskAssignedRole,
				}
				completedTill = false
			} else {
				ObjectArray[ index ] = {
					id: task.id,
					name: task.name,
					completedBy: task.taskAssignedRole,
					completedOn: task.updatedAt,
					previousTasks: task.previousTasks,
					animated: false,
					isCompleted: completedTill,
					assignedTo: task.taskAssignedRole,
				}
			}
		}
		return ObjectArray
	}

	const mappedTasks = mapTasksToTaskObjects()
	console.log('mapped task',session.user.isDoctor, mappedTasks)

	const handleHidden = ( id: string ) => {
		const hiddenElement = document.getElementById( id )
		if ( hiddenElement ) {
			hiddenElement.hidden = !hiddenElement.hidden // Toggle the hidden property
		}
	}

	return (
		<React.Suspense fallback={<LoadingOverlay />}>
			<div
				style={{
					backgroundColor: "#d9d9d9",
					width: "-webkit-fill-available",
					borderRadius: "10px",
				}}
				className="d-flex justify-content-center w-100"
			>
				<div style={{ width: "100%", justifyContent: "center" }}>
					{mappedTasks.map( ( tasks: taskObject ) => {
						console.log("assignedTo" ,tasks.assignedTo)
						return (
							<CheckTimelinePermissions assignedTo = {tasks.assignedTo}>
							<div className="mx-5 my-2">
								<Card className="" style={{ backgroundColor: "#d9d9d9" }}>
									<Card.Body
										onMouseEnter={() => handleHidden( tasks.id )}
										onMouseLeave={() => handleHidden( tasks.id )}
									>
										<span className="d-flex justify-content-between">
											{tasks.name}
											<div className="d-flex gap-2">
												{tasks.isCompleted && <FontAwesomeIcon icon={faCheck} color="green" />}
												{tasks.previousTasks.length >= 1 && (
													<FontAwesomeIcon icon={faArrowCircleRight} />
												)}
											</div>
											{tasks.previousTasks.length >= 1 && (
												<>
													<div
														id={tasks.id}
														hidden
														className="card"
														style={{
															position: "absolute",
															left: "10rem",
															bottom: "12px",
															width: "5rem",
														}}
													>
														<hr />
														{tasks.previousTasks.map( ( prev ) => {
															const namecheck = labelFinder( prev, mappedTasks )?.name
															if ( namecheck ) {
																return (
																	<div className="text-center">
																		{labelFinder( prev, mappedTasks )?.name}
																		<hr />
																	</div>
																)
															}
														} )}
													</div>
												</>
											)}
										</span>
									</Card.Body>
								</Card>
							</div>
							</CheckTimelinePermissions>
						)
					} )}
				</div>
			</div>
		</React.Suspense>
	)
}

const labelFinder = ( id: string, tasksList: taskObject[] ) => {
	const item = tasksList.find( ( x ) => x.id == id )
	return item
}
