import { faRefresh } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Button, Table } from "react-bootstrap"
import {
	useDefaultPaymentMutation,
	useQueryListPaymentCards,
	useSetupVerifiedMutation,
} from "../../../services/CelebPayments.Service"
import { useProfileDialogStore } from "../../../store/profileDialogStore"
import { CheckGenericPermission } from "../../permissions/CheckPermision"
import PaymentMethodAddDialogDialog from "./PaymentMethodAddDialog.dialog"
const ProfilePaymentMethodsTab = () => {
	return (
		<React.Suspense>
			<ProfilePaymentMethodsImpl />
		</React.Suspense>
	)
}

const ProfilePaymentMethodsImpl = () => {
	const profile = useProfileDialogStore()
	const { data } = useQueryListPaymentCards()
	const { mutate: notifySetupVerified } = useSetupVerifiedMutation()
	const { mutate: makeCardDefault } = useDefaultPaymentMutation()
	const cardListing = data.listing
	return (
		<React.Fragment>
			<div className="text-end d-flex gap-3 flex-end justify-content-end align-items-center">
				<FontAwesomeIcon icon={faRefresh} onClick={() => notifySetupVerified()} /> Refresh cards
				<CheckGenericPermission genericLabel="Add_Payment_Method">
					<Button className="btn btn-primary" onClick={() => profile.setPaymentCreateOpen( true )}>
						Add payment method
					</Button>
				</CheckGenericPermission>
			</div>
			<Table striped bordered className="my-1">
				<thead className="thead-dark">
					<tr>
						<th>Brand</th>
						<th>Exp. Month</th>
						<th>Exp. Year</th>
						<th>Card Number</th>
						<th>Default</th>
						<th>Stripe Customer ID</th>
						<th>User ID</th>
						<th>Created At</th>
						<th>Updated At</th>
						<th>Make Default</th>
					</tr>
				</thead>
				<tbody>
					{cardListing.map( ( card: Card ) => (
						<tr key={card.id}>
							<td>{card.brand}</td>
							<td>{card.expMonth}</td>
							<td>{card.expYear}</td>
							<td>{card.cardNumber}</td>
							<td>{card.isDefault ? "Yes" : "No"}</td>
							<td>{card.stripCustomerId}</td>
							<td>{card.userId}</td>
							<td>{new Date( card.createdAt ).toLocaleString()}</td>
							<td>{new Date( card.updatedAt ).toLocaleString()}</td>
							<td>
								<Button onClick={() => makeCardDefault( card.id )}>Make Default</Button>
							</td>
						</tr>
					) )}
				</tbody>
			</Table>

			{profile.paymentCreateOpen && <PaymentMethodAddDialogDialog />}
		</React.Fragment>
	)
}
interface Card {
	id: string
	stripId: string
	brand: string
	expMonth: number
	expYear: number
	cardNumber: number
	isDefault: boolean
	stripCustomerId: string
	userId: string
	createdAt: string
	updatedAt: string
}
export default ProfilePaymentMethodsTab
