import Pagination from "rc-pagination/lib/Pagination"
import React from "react"
import { Badge, Dropdown, DropdownButton, Table } from "react-bootstrap"
import Moment from "react-moment"
import { Params, useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import LoadingOverlay from "../../assets/styles/components/loader/loadingOverlay"
import {
	PatientEditSchema,
	useTaskCompleteServicesQuery,
	useTreatmentStatusMutation,
} from "../../services/CelebTreatment.Service"
import { usePatientDialogStore } from "../../store/patientDailogStore"
import { TreatmentStates } from "../../utils/appConstants"
import { CheckGenericPermission } from "../permissions/CheckPermision"
import { infoMapperNames } from "../utlity/GenricFunctions"

const CompletedTable = () => {
	const paramsData: Readonly<Params<string>> = useParams()
	const [ searchParams, setSearchParams ] = useSearchParams()
	const patientStore = usePatientDialogStore()
	const [ currentPage, setCurrentPage ] = React.useState<number>( 0 )
	const status = searchParams.get( "status" )
	const { mutateAsync: updateTaskStatus } = useTreatmentStatusMutation()
	const searchPatient = searchParams.get( "searchPatient" )

	const { data: tableData } = useTaskCompleteServicesQuery( {
		search: searchPatient || "",
		limit: "10",
		offset: currentPage + "",
		serviceId: paramsData.id ? paramsData.id : "",
		status: status ? status : "",
	} )
	React.useEffect( () => {
		addNewQueryParam( tableData.treatment[ 0 ]?.id )
	}, [ status ] )

	const onTreatmentStateChange = async ( treatmentId: string, statusTask: string ) => {
		updateTaskStatus( { treatmentId, status: statusTask } )
	}

	const addNewQueryParam = ( patientID: string ) => {
		if ( patientID ) {
			const newParams = new URLSearchParams( searchParams )
			newParams.set( "PatientId", patientID )
			setSearchParams( newParams )
		}
	}

	return (
		<React.Suspense fallback={<LoadingOverlay />}>
			<Table striped hover>
				<thead>
					<tr>
						<th>Patient Name</th>
						<th>Patient ID</th>
						<th>Submit Date</th>
						<th>Clinic</th>
						<th>Doctor</th>
						<th>Action Buttons</th>
					</tr>
				</thead>
				<tbody>
					{tableData.treatment.map( ( x: PatientEditSchema ) => {
						return (
							<tr onClick={() => addNewQueryParam( x.id )}>
								<td>
									{infoMapperNames( x.patient, [
										"entityId",
										"dob",
										"id",
										"gender",
										"ethenicity",
										"email",
										"phone",
									] )}{" "}
									<Badge bg="secondary" pill hidden={!x.isReplacementOrder}>
										Replaced order
									</Badge>
								</td>
								<td>{x.patient.entityId}</td>
								<td>{<Moment date={x.createdAt} format="MM/DD/YYYY" />}</td>
								<td>{x.treatmentPlanDoctorAddress.name}</td>
								<td>{x.doctor.firstName + " " + x.doctor.lastName}</td>
								<td className="d-flex gap-2">
									<CheckGenericPermission genericLabel={"View_TP"}>
										<a className="btn btn-primary" target="_blank" href={"/editor/" + x.id}>
											View TP
										</a>
									</CheckGenericPermission>
									<DropdownButton id="dropdown-basic-button" title="">
										{status == "completed" && (
											<>
												<CheckGenericPermission genericLabel={"Patient_Replace"}>
													<Dropdown.Item
														href="#PatientInfo"
														onClick={() => patientStore.setReplaceOpen( x.id )}
													>
														Replace
													</Dropdown.Item>
												</CheckGenericPermission>
												<CheckGenericPermission genericLabel={"Patient_Archive"}>
													<Dropdown.Item
														onClick={() => onTreatmentStateChange( x.id, TreatmentStates.ARCHIVED )}
													>
														Archive
													</Dropdown.Item>
												</CheckGenericPermission>
											</>
										)}

										{status == TreatmentStates.PAUSED && (
											<>
												<Dropdown.Item
													onClick={() => onTreatmentStateChange( x.id, TreatmentStates.ACTIVE )}
												>
													Unpause
												</Dropdown.Item>
											</>
										)}

										{status == TreatmentStates.ARCHIVED && (
											<>
												<Dropdown.Item
													onClick={() => onTreatmentStateChange( x.id, TreatmentStates.COMPLETED )}
												>
													Unarchive
												</Dropdown.Item>
											</>
										)}
										<CheckGenericPermission genericLabel={"Patient_Transfer"}>
											<Dropdown.Item>Transfer</Dropdown.Item>
										</CheckGenericPermission>
									</DropdownButton>
								</td>
							</tr>
						)
					} )}
				</tbody>
			</Table>
			<Pagination
				className="fixed-bottom-center"
				total={typeof tableData.total == "number" ? tableData.total : Number( tableData.total )}
				pageSize={10}
				onChange={( pagenumber ) => setCurrentPage( ( pagenumber - 1 ) * 10 )}
				align={"center"}
			/>
		</React.Suspense>
	)
}

export default CompletedTable
