import Pagination from "rc-pagination/lib/Pagination"
import React from "react"
import { Dropdown, DropdownButton, Form, Table } from "react-bootstrap"
import Moment from "react-moment"
import { Params, useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import LoadingOverlay from "../../assets/styles/components/loader/loadingOverlay"
import { useTreatmentStatusMutation } from "../../services/CelebTreatment.Service"
import {
	TreatmentSchemaType,
	useTaskServicesQuery,
} from "../../services/CelebTreatmentTask.Service"
import { usePatientDialogStore } from "../../store/patientDailogStore"
import { useSidebarStore } from "../../store/sideBarStore"
import { useTaskCompletionDialogStore } from "../../store/taskCompletionDialogStore"
import { userSessionStore } from "../../store/userSessionStore"
import { TreatmentStates } from "../../utils/appConstants"
import { CheckGenericPermission } from "../permissions/CheckPermision"
import { infoMapperNames } from "../utlity/GenricFunctions"

const TasksTable = () => {
	return (
		<React.Suspense>
			<LandingPageImpl />
		</React.Suspense>
	)
}

const LandingPageImpl = () => {
	const paramsData: Readonly<Params<string>> = useParams()
	const [ searchParams, setSearchParams ] = useSearchParams()
	const tasksStore = useTaskCompletionDialogStore()
	const patientStore = usePatientDialogStore()
	const [ currentPage, setCurrentPage ] = React.useState<number>( 0 )
	const status = searchParams.get( "status" )
	const searchPatient = searchParams.get( "searchPatient" )
	const sessionUser = userSessionStore()
	const sideBarStore = useSidebarStore()
	const { mutateAsync: updateTaskStatus } = useTreatmentStatusMutation()
	const category = sessionUser.category.type == "provide" ? "providerCategory" : "consumerCategory"
	const { data: tableData } = useTaskServicesQuery( {
		path: sessionUser.category.path ? sessionUser.category.path : "me",
		dataProps: {
			search: searchPatient || "",
			limit: "10",
			offset: currentPage + "",
			serviceId: paramsData.id ? paramsData.id : "",
			status: status || "active",
			...( sessionUser.category.isAll && {
				[ "taskAssignedRole" ]: sessionUser.category.taskAssignedRole,
			} ),
			...( sessionUser.category.id && { [ category ]: sessionUser.category.id } ),
		},
	} )

	React.useEffect( () => {
		if ( tableData?.treatment ) {
			tableData?.treatment[ 0 ] && addNewQueryParam( tableData?.treatment[ 0 ] )
		}
	}, [ category, status ] )

	const taskStoreChange = ( isTaskChecked: boolean, task: TreatmentSchemaType ) => {
		if ( isTaskChecked ) return tasksStore.setLandingPageTasks( task )

		const updatedTask: TreatmentSchemaType[] = tasksStore.landingPageTasks.filter(
			( x: TreatmentSchemaType ) => {
				return x.id !== task.id
			},
		)

		tasksStore.updateLandingPageTasks( updatedTask )
	}

	const idMapper = ( patientID: TreatmentSchemaType ) => {
		if ( patientID.treatmentId ) {
			return patientID.treatmentId
		} else if ( patientID.treatmentPlanServiceTaskTreatment.id ) {
			const taskTreatment = patientID.treatmentPlanServiceTaskTreatment.id
			return taskTreatment
		} else {
			alert( "incorrect inferer" )
		}
	}

	const onTreatmentStateChange = async (
		patientID: TreatmentSchemaType,
		status: TreatmentStates,
	) => {
		const id = idMapper( patientID )
		id && updateTaskStatus( { treatmentId: id, status } )
	}

	const addNewQueryParam = ( patientID: TreatmentSchemaType ) => {
		const newParams = new URLSearchParams( searchParams )
		const id = idMapper( patientID )
		id && newParams.set( "PatientId", id )
		id && setSearchParams( newParams )

		sideBarStore.setTimelineData( patientID )
	}

	const onEdit = ( patientID: TreatmentSchemaType ) => {
		const id = idMapper( patientID )
		id && patientStore.setTypesEditOpen( id )
	}

	return (
		<React.Suspense fallback={<LoadingOverlay />}>
			<Table striped hover>
				<thead>
					<tr>
						<th>Check</th>
						<th>Patient Name</th>
						<th>Task</th>
						<th>Patient ID</th>
						<th>Submit Date</th>
						<th>Clinic</th>
						<th>Doctor</th>
						<th>Action Buttons</th>
					</tr>
				</thead>
				<tbody>
					{tableData?.treatment?.map( ( x: TreatmentSchemaType ) => {
						return (
							<tr
								onClick={() => {
									addNewQueryParam( x )
								}}
							>
								<td onClick={( event ) => event?.stopPropagation()}>
									<span className="d-flex gap-2">
										{sessionUser.category.path != "awaiting" && (
											<CheckGenericPermission genericLabel="Task_Select">
												<Form.Check
													checked={
														tasksStore?.landingPageTasks.find( ( z ) => z.id === x.id ) ? true : false
													}
													onClick={( e ) => taskStoreChange( e.currentTarget.checked, x )}
												></Form.Check>
											</CheckGenericPermission>
										)}
									</span>
								</td>
								<td>{infoMapperNames( x.treatmentPlanPatient, [ "entityId" ] )}</td>
								<td>{x?.treatmentPlanServiceTask?.name}</td>
								<td>{x.treatmentPlanPatient.entityId}</td>
								<td>{<Moment date={x.createdAt} format="MM/DD/YYYY" />}</td>
								<td>{x.treatmentPlanServiceTaskTreatment.treatmentPlanDoctorAddress.na}</td>
								<td>{x.treatmentPlanDoctor.firstName + " " + x.treatmentPlanDoctor.lastName}</td>
								<td className="d-flex gap-2">
									<CheckGenericPermission genericLabel={"View_TP"}>
										<a
											className="btn btn-primary"
											target="_blank"
											href={"/editor/" + x.id + "?treatmentId=" + idMapper( x )}
										>
											View TP
										</a>
									</CheckGenericPermission>
									<DropdownButton id="dropdown-basic-button" title="">
										<CheckGenericPermission genericLabel={"Patient_Replace"}>
											{status == "completed" ? (
												<Dropdown.Item
													href="#PatientInfo"
													onClick={() => patientStore.setReplaceOpen( x.treatmentId )}
												>
													Replace
												</Dropdown.Item>
											) : (
												""
											)}
										</CheckGenericPermission>
										<CheckGenericPermission genericLabel={"Patient_Edit"}>
											<Dropdown.Item href="#PatientInfo" onClick={() => onEdit( x )}>
												Edit
											</Dropdown.Item>
										</CheckGenericPermission>
										<CheckGenericPermission genericLabel={"Patient_Pause"}>
											<Dropdown.Item
												onClick={() => onTreatmentStateChange( x, TreatmentStates.PAUSED )}
											>
												Pause
											</Dropdown.Item>
										</CheckGenericPermission>
										<CheckGenericPermission genericLabel={"Patient_Transfer"}>
											<Dropdown.Item>Transfer</Dropdown.Item>
										</CheckGenericPermission>
									</DropdownButton>
								</td>
							</tr>
						)
					} )}
				</tbody>
			</Table>

			<Pagination
				className="fixed-bottom-center"
				total={typeof tableData.total == "number" ? tableData.total : Number( tableData.total )}
				pageSize={10}
				onChange={( pagenumber ) => setCurrentPage( ( pagenumber - 1 ) * 10 )}
				align={"center"}
			/>
		</React.Suspense>
	)
}

export default TasksTable
