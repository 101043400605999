import { useMutation } from "@tanstack/react-query"
import React from "react"
import { Button, Modal, Nav, Navbar } from "react-bootstrap"
import { FieldErrors, useFormContext } from "react-hook-form"
import { useLocation } from "react-router"
import useCustomErrorMarkerTry from "../../../hooks/patient/customErrorMarker"
import { queryClient } from "../../../providers/QueryProvider"
import { CelebMedicalFileService } from "../../../services/CelebMedicalFile.Service"
import { PatientSchemaType, usePatientCreateMutation } from "../../../services/CelebPatient.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import { useTeethStore } from "../../../store/toothSelectionStore"
import { userSessionStore } from "../../../store/userSessionStore"
import { TreatmentFileCategory } from "../../../utils/appConstants"
import { clearHash } from "../../utlity/GenricFunctions"
import ProgressBarLoader from "../../utlity/ProgressBar.Loader"
import { photosLibraryReset } from "../editPatients-v2/defaultFileShowCase"
import "./Patient.css"
const PatientNavbar = () => {
	const { setValue, getValues, control, reset } = useFormContext<PatientSchemaType>()
	const clearHashClear = clearHash()
	const {
		cbctDocumentJson,
		individualPhoto,
		xRays,
		scan,
		patientCompositePhotoJson,
		navErrors,
		errorToShow,
		setErrorToShow,
		clearState,
		setTypesEditOpen,
		setIsUploadFromScanner,
	} = usePatientDialogStore()
	const { mutateAsync: createPatient } = usePatientCreateMutation()
	const { teethBonding, teethExtraction, resetTeethSelection } = useTeethStore()
	const { setProgressLoaderObj, setProgressLoaderJson } = userSessionStore()
	const { customErrorMarker } = useCustomErrorMarkerTry()
	const locationHash = useLocation()
	const additionalApiRequirments = async () => {
		setProgressLoaderObj( "max", 100 )
		setProgressLoaderObj( "now", 0 )
		const date = Date.now()
		const doctorAddress = getValues( "selectedOffice" )
		setValue( "office", doctorAddress )
		setValue( "treatmentPlan.teethBonding", teethBonding )
		setValue( "treatmentPlan.teethExtraction", teethExtraction )
		setValue( "treatmentPlan.treatmentStart", date )
		setValue( "treatmentPlan.treatmentPlanDoctorAddressId", doctorAddress )
		control.handleSubmit( checkSecondaryFilesValidation, onError )()
	}

	const uploadDocumentsToServer = async () => {
		setProgressLoaderObj( "show", true )
		setProgressLoaderObj( "now", 10 )
		const uploadCbctData = await uploadFiles( cbctDocumentJson, TreatmentFileCategory.PATIENT_CBCT )
		setProgressLoaderObj( "now", 20 )

		const uploadPhotoDataComposite = await uploadFiles(
			patientCompositePhotoJson,
			TreatmentFileCategory.PATIENT_PHOTO,
		)
		const uploadPhotoDataJson = await uploadFiles(
			individualPhoto,
			TreatmentFileCategory.PATIENT_PHOTO,
		)
		setProgressLoaderObj( "now", 30 )
		const uploadXRaysData = await uploadFiles( xRays, TreatmentFileCategory.PATIENT_XRAY )
		setProgressLoaderObj( "now", 40 )
		const uploadScanData = await uploadFiles( scan, TreatmentFileCategory.PATIENT_SCAN )
		setProgressLoaderObj( "now", 50 )
		const treatmentFiles = [
			...uploadCbctData,
			...uploadPhotoDataComposite,
			...uploadXRaysData,
			...uploadScanData,
			...uploadPhotoDataJson,
		].filter( ( file ): file is UploadData =>
			file !== undefined && file.uri.length >= 1 ? true : false,
		)
		setProgressLoaderObj( "now", 80 )
		setValue( "treatmentPlan.treatmentFiles", treatmentFiles )
		control.handleSubmit( submit, onError )()
	}

	const uploadFiles = async ( files: { [key: string]: File | null }, category: string ) => {
		const uploadPromises = Object.keys( files ).map( async ( fileKey ) => {
			const file = files[ fileKey ]
			if ( file ) {
				return await uploadMedicalFiles( file, category, fileKey )
			}
			return undefined
		} )
		return await Promise.all( uploadPromises )
	}

	const checkSecondaryFilesValidation = ( value: PatientSchemaType ) => {
		if ( customErrorMarker() ) return null
		uploadDocumentsToServer()
	}

	const submit = ( value: PatientSchemaType ) => {
		createPatient( value )
		if ( errorToShow.createPatient !== "" ) return
		clearFormData()
		setProgressLoaderJson( {
			max: 100,
			message: "Data Uploaded SuccessFully",
			now: 100,
			show: false,
		} )
	}

	const onError = ( errorData: FieldErrors<PatientSchemaType> ) => {
		console.log( errorData )
		customErrorMarker( errorData )
	}

	const clearFormData = () => {
		reset()
		resetTeethSelection()
		clearState()
		setTypesEditOpen( null )
		setIsUploadFromScanner( null )
		photosLibraryReset()
		clearHashClear()
		setErrorToShow( "" )
	}

	return (
		<React.Fragment>
			<Navbar id="navbarScroll" as={Modal.Header}>
				<Nav variant="tabs" defaultActiveKey="#PatientInfo" className="d-flex mt-2 me-auto">
					<Nav.Link
						href="#PatientInfo"
						active={locationHash.hash == "#PatientInfo"}
						className={navErrors.infoTab}
					>
						Patient Info
					</Nav.Link>
					<Nav.Link href="#Scan" className={navErrors.scanTab}>
						Scan
					</Nav.Link>
					<Nav.Link
						href="#Instructions"
						active={locationHash.hash == "#Instructions"}
						className={navErrors.instructions}
					>
						Instructions
					</Nav.Link>
					<Nav.Link
						href="#Photos"
						active={locationHash.hash == "#Photos"}
						className={navErrors.photos}
					>
						Photos
					</Nav.Link>
					<Nav.Link href="#X-rays" active={locationHash.hash == "#X-rays"}>
						X-rays
					</Nav.Link>
					<Nav.Link href="#CBCT" active={locationHash.hash == "#CBCT"}>
						CBCT
					</Nav.Link>
				</Nav>
				<span>
					<Button onClick={additionalApiRequirments} className="mx-1">
						Create Patient
					</Button>
					<Button type="reset" onClick={clearFormData} variant="secondary">
						Cancel
					</Button>
				</span>
			</Navbar>
			<ProgressBarLoader />
		</React.Fragment>
	)
}

export const uploadMedicalFiles = async ( file: File, category: string, subCategory: string ) => {
	const medicalFileService = CelebMedicalFileService.getInstance()
	const fileUploadResponse = await medicalFileService.getUploadUrls( [ file?.name ] )
	const uploadUrl = fileUploadResponse.data.data[ 0 ].uploadUrl
	const response = await medicalFileService.uploadFileToS3( uploadUrl, file )
	if ( response.status === 200 ) {
		const fileName = uploadUrl.split( "?" )[ 0 ].split( "/" )[ 3 ]
		const dataReturn = {
			category: category,
			subCategory: subCategory,
			originalFileName: file?.name,
			uri: fileName,
		}
		return dataReturn
	}
}

export const updateTaskFilesMutation = () => {
	return useMutation( {
		mutationFn: ( {
			fileData,
			category,
			subCategory,
		}: {
			fileData: File
			category: string
			subCategory: string | ""
		} ) => uploadMedicalFiles( fileData, category, subCategory ),
		onSuccess: () => {
			return queryClient.clear()
		},
	} )
}

type UploadData = {
	category: string
	subCategory: string
	originalFileName: string
	uri: string
}

const customValidationFunction = (
	cbctDocumentJson: { [key: string]: File | null },
	individualPhoto: { [key: string]: File | null },
	xRays: { [key: string]: File | null },
	scan: { [key: string]: File | null },
	patientCompositePhotoJson: { [key: string]: File | null },
) => {
	const checkIndividualPhotos = Object.values( individualPhoto ).filter( Boolean )
	const checkCbctDocumentJson = Object.values( cbctDocumentJson ).filter( Boolean )
	const checkXRays = Object.values( xRays ).filter( Boolean )
	const checkScan = Object.values( scan ).filter( Boolean )
	const checkPatientCompositePhotoJson = Object.values( patientCompositePhotoJson ).filter( Boolean )

	return {
		checkIndividualPhotos: checkIndividualPhotos,
		checkCbctDocumentJson: checkCbctDocumentJson,
		checkXRays: checkXRays,
		checkScan: checkScan,
		checkPatientCompositePhotoJson: checkPatientCompositePhotoJson,
	}
}

export default PatientNavbar
