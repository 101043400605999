import React from "react"
import { useSearchParams } from "react-router-dom"
import { useValidateLinkMuation } from "../../services/CelebAuth.Service"
import { error } from "console"
import { Card } from "react-bootstrap"

const ResetAuth = ( { children }: { children: React.ReactNode } ) => {
	const [ searchParams, setSearchParams ] = useSearchParams()
	const resetLink = searchParams.get( "resetlink" )
	const { data: data } = useValidateLinkMuation( resetLink + "" )
	if ( data == "reseted password" ) {
		return MyInvalidatePage( { errormessage: "Password already reseted" } )
	} else if ( data === true ) {
		return <>{children}</>
	} else {
		return MyInvalidatePage( { errormessage: "Invalid link" } )
	}
}

export default ResetAuth

const MyInvalidatePage = ( { errormessage }: { errormessage: string } ) => {
	return (
		<div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
			<Card>
				<Card.Img variant="top" src="/invalid.jpg" />
				<Card.Body>
					<Card.Text className="text-center">
						<h1 className="text-center"><b>{errormessage}</b></h1>
						<a href="/" className="btn btn-primary text-center">Home</a>
					</Card.Text>
				</Card.Body>
			</Card>
		</div>
	)
}
