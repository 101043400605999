import { useMutation, useSuspenseQuery } from "@tanstack/react-query"
import { queryClient } from "../providers/QueryProvider"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"

export class CelebPaymentsService {
	static _instance: CelebPaymentsService = new CelebPaymentsService()

	private constructor() {
		CelebPaymentsService._instance = this
	}

	static getInstance(): CelebPaymentsService {
		return CelebPaymentsService._instance
	}

	async getSetupIntent() {
		const requestUrl = `${constants.PAYMENTS}/setupintent`
		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}

	async notifySetupVerified() {
		const requestUrl = `${constants.PAYMENTS}/paymentmethods/refresh`
		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}

	async getCards() {
		const requestUrl = `${constants.PAYMENTS}/cards/me`
		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}

	async makeCardDefault( id: string ) {
		const requestUrl = `${constants.PAYMENTS}/default/${id}`
		return await CelebRequestGeneratorService.processPutRequest( requestUrl, null )
	}
}

export const listUseQueryListPaymentCardsFn = () =>
	[ "listCards", `${constants.PAYMENTS}/cards/me` ].filter( Boolean )

export const useQueryListPaymentCards = () =>
	useSuspenseQuery( {
		queryKey: [ "listCards", `${constants.PAYMENTS}/cards/me` ],
		queryFn: () =>
			CelebPaymentsService.getInstance()
				.getCards()
				.then( ( res ) => ( { data: res.data.data } ) )
				.then( ( { data } ) => {
					return {
						listing: data,
					}
				} ),
	} )

export const useSetupIntentQuery = () =>
	useSuspenseQuery( {
		queryKey: [ "Payment", `${constants.PAYMENTS}/cards/me` ],
		queryFn: () =>
			CelebPaymentsService.getInstance()
				.getSetupIntent()
				.then( ( res ) => ( { data: res.data.data } ) )
				.then( ( { data } ) => {
					return {
						secretKey: data,
					}
				} ),
	} )

export const useSetupVerifiedMutation = () =>
	useMutation( {
		mutationFn: () => CelebPaymentsService.getInstance().notifySetupVerified(),
		onSuccess: () => {
			return queryClient.invalidateQueries( {
				queryKey: listUseQueryListPaymentCardsFn(),
				exact: false,
			} )
		},
	} )
export const useDefaultPaymentMutation = () =>
	useMutation( {
		mutationFn: ( id: string ) => CelebPaymentsService.getInstance().makeCardDefault( id ),
		onSuccess: () => {
			return queryClient.invalidateQueries( {
				queryKey: listUseQueryListPaymentCardsFn(),
				exact: false,
			} )
		},
	} )
