import React from "react"
import "./assets/styles/components/celebServiceTabs.css"
import "./assets/styles/components/navbar.css"
import "./assets/styles/pages/landing.page.css"
import { AccountsListDialog } from "./components/dialogs/accounts-v2/AccountsList.dialog"
import PatientEditDialog from "./components/dialogs/editPatients-v2/PatientEdit.dialog"
import PatientAddDialog from "./components/dialogs/patient-v2/PatientAdd.dialog"
import ProfileDialog from "./components/dialogs/profile-v2/Profile.dialog"
import PatientReplaceDialog from "./components/dialogs/replaceOrder-v2/PatientReplace.dialog"
import ServiceListDialog from "./components/dialogs/services-v2/ServicesList.dialog"
const TaskCompletionDialog = React.lazy(
	() => import( "./components/dialogs/tasks/TaskCompletion.dialog" ),
)

import LoadingOverlay from "./assets/styles/components/loader/loadingOverlay"
import { ToastPopup } from "./components/toast/Toast"
import ProgressBarLoader from "./components/utlity/ProgressBar.Loader"
import { usePatientDialogStore } from "./store/patientDailogStore"
const AppCommon = () => {
	const patientStore = usePatientDialogStore()

	return (
		<React.Suspense fallback={<LoadingOverlay />}>
			<ServiceListDialog />
			<AccountsListDialog />
			<PatientAddDialog />
			<ProfileDialog />
			<TaskCompletionDialog />
			{patientStore.replaceOpen ? <PatientReplaceDialog /> : <></>}
			{patientStore.typesEditOpen ? <PatientEditDialog /> : <></>}
			<ProgressBarLoader />
			<ToastPopup />
		</React.Suspense>
	)
}

export default AppCommon
