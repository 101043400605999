import { useCallback } from "react"
import { FieldErrors, useFormContext } from "react-hook-form"

import { usePatientDialogStore } from "../../store/patientDailogStore"
import { validEmail } from "../../utils/Validations"

const useCustomErrorMarkerTry = () => {
	const { getValues, control } = useFormContext<any>()
	const checkingCondition = {
		email: true,
		treatmentInfo: true,
		treatmentPlan: true,
		scan: true,
		photos: true,
		replaceOrder: true,
	}
	const {
		cbctDocumentJson,
		individualPhoto,
		xRays,
		scan,
		replaceOrderPhoto,
		setNavErrors,
		setCustomError,
	} = usePatientDialogStore()

	const resetFirst = () => {
		checkingCondition.email = true
		checkingCondition.treatmentInfo = true
		checkingCondition.treatmentPlan = true
		checkingCondition.scan = true
		checkingCondition.photos = true
		checkingCondition.replaceOrder = true
	}

	const customErrorMarker = useCallback(
		( errorData?: FieldErrors<any> ) => {
			const {
				treatmentPlan,
				firstName,
				replaceOrder,
				lastName,
				gender,
				assignedDoctor,
				selectedOffice,
			} = control._formState.errors || errorData
			resetFirst()

			if ( treatmentPlan ) {
				setNavErrors( "instructions", "red" )
				checkingCondition.treatmentPlan = false
				window.location.href = "#Instructions"
			}

			if ( replaceOrder ) {
				setNavErrors( "replace", "red" )
				checkingCondition.replaceOrder = false
				window.location.href = "#Replace"
			}

			const email = getValues( "email" )

			if ( email ) {
				const checkEmail = validEmail.test( email )
				if ( !checkEmail ) {
					setCustomError( "email", "Email is not valid" )
					setNavErrors( "infoTab", "red" )
					checkingCondition.email = false
					window.location.href = "#PatientInfo"
				} else {
					setCustomError( "email", "" )
					setNavErrors( "infoTab", "none" )
					checkingCondition.email = true
				}
			} else {
				setCustomError( "email", "" )
				setNavErrors( "infoTab", "none" )
			}

			if ( firstName || lastName || gender || selectedOffice || assignedDoctor ) {
				setNavErrors( "infoTab", "red" )
				checkingCondition.treatmentInfo = false
				window.location.href = "#PatientInfo"
			}

			const checking = customValidationFunction(
				cbctDocumentJson,
				individualPhoto,
				xRays,
				scan,
				replaceOrderPhoto,
			)

			if ( checking.checkScan.length <= 1 ) {
				checkingCondition.scan = true
			}

			if ( checking.checkIndividualPhotos.length <= 6 ) {
				if ( checking.checkPatientCompositePhotoJson.length <= 0 ) {
					setNavErrors( "photos", "none" )
					checkingCondition.photos = true
				}
			}

			const check = Object.values( checkingCondition ).map( ( x ) => {
				if ( x == false ) {
					return "fail"
				} else {
					return "pass"
				}
			} )

			return check.includes( "fail" )
		},

		[],
	)

	return { customErrorMarker }
}

export const customValidationFunction = (
	cbctDocumentJson: { [key: string]: File | null },
	individualPhoto: { [key: string]: File | null },
	xRays: { [key: string]: File | null },
	scan: { [key: string]: File | null },
	patientCompositePhotoJson: { [key: string]: File | null },
) => {
	const checkIndividualPhotos = Object.values( individualPhoto ).filter( Boolean )
	const checkCbctDocumentJson = Object.values( cbctDocumentJson ).filter( Boolean )
	const checkXRays = Object.values( xRays ).filter( Boolean )
	const checkScan = Object.values( scan ).filter( Boolean )
	const checkPatientCompositePhotoJson = Object.values( patientCompositePhotoJson ).filter( Boolean )

	return {
		checkIndividualPhotos,
		checkCbctDocumentJson,
		checkXRays,
		checkScan,
		checkPatientCompositePhotoJson,
	}
}

export default useCustomErrorMarkerTry
