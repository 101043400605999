import React from "react"
import { CloseButton } from "react-bootstrap"

import { PatientDialogState, usePatientDialogStore } from "../../../store/patientDailogStore"
import { photosLibrary } from "../editPatients-v2/defaultFileShowCase"
import { Addbutton } from "./PatientPhotos.tab"

const PatientPhotoComposite = () => {
	const { patientCompositePhotoJson, setPatientCompositePhotoJson } = usePatientDialogStore()
	const img = patientCompositePhotoJson[ "patient_photo_composite" ]
	return (
		<React.Fragment>
			{img ? (
				<center>
					<SingleImageDisplay
						img={img}
						clearPhoto={() => setPatientCompositePhotoJson( "patient_photo_composite", null )}
					/>
				</center>
			) : (
				<ImageGrid />
			)}
		</React.Fragment>
	)
}

export default PatientPhotoComposite

const SingleImageDisplay = ( { img, clearPhoto }: { img: File; clearPhoto: () => void } ) => {
	return (
		<div
			className="card text-bg-dark d-flex"
			style={{ height: "80%", width: "80%", position: "relative" }} // Set fixed height and width
			onClick={( x ) => ""}
		>
			<img
				alt=""
				src={img ? URL.createObjectURL( img ) : ""}
				className="card-img"
				style={{ height: "100%", width: "100%", objectFit: "cover" }} // Ensure the image covers the container
			/>
			{img && (
				<CloseButton
					onClick={( e ) => {
						e.preventDefault()
						clearPhoto()
					}}
					style={{
						position: "absolute",
						top: "0px",
						right: "0px",
						backgroundColor: "#E3E0F0",
						borderRadius: "50%",
					}}
				/>
			)}
		</div>
	)
}
export const ImageGrid = () => {
	return (
		<section className="d-flex justify-content-center">
			<div>
				<span className="d-flex gap-3 my-2">
					<DummyImageComponent property="patient_photo_face_side" />
					<DummyImageComponent property="patient_photo_face_front" />
					<DummyImageComponent property="patient_photo_face_front_teeth" />
				</span>
				<span className="d-flex gap-3 my-2">
					<DummyImageComponent property="patient_photo_jaw_upper" />
					<CardComponent property="patient_photo_composite" />
					<DummyImageComponent property="patient_photo_jaw_lower" />
				</span>
				<span className="d-flex gap-3 my-2">
					<DummyImageComponent property="patient_photo_jaw_right" />
					<DummyImageComponent property="patient_photo_jaw_front" />
					<DummyImageComponent property="patient_photo_jaw_left" />
				</span>
			</div>
		</section>
	)
}

const CardComponent = ( {
	property,
}: {
	property: keyof PatientDialogState["patientCompositePhotoJson"]
} ) => {
	const imageRef = React.useRef<HTMLInputElement | null>( null )
	const { patientCompositePhotoJson, setPatientCompositePhotoJson } = usePatientDialogStore()
	const fileImageSaver = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		e.preventDefault()
		if ( e.currentTarget.files ) {
			console.log( e.currentTarget.files[ 0 ] )
			setPatientCompositePhotoJson( property, e.currentTarget.files[ 0 ] )
		}
	}
	const img = patientCompositePhotoJson[ property ]

	return (
		<div>
			<span className="d-flex" style={{ height: "10rem", width: "10rem" }}>
				<div
					className="card text-bg-secondary"
					onClick={() => {
						imageRef.current?.click()
					}}
					style={{ width: "10rem" }}
				>
					<img
						alt=""
						src={img ? URL.createObjectURL( img ) : ""}
						className="img-fluid"
						style={{ height: "-webkit-fill-available" }}
					/>
					{img && (
						<CloseButton
							onClick={( e ) => {
								e.preventDefault()
								setPatientCompositePhotoJson( property, null )
							}}
							style={{
								position: "absolute",
								top: "0px",
								right: "0px",
								backgroundColor: "#E3E0F0",
								borderRadius: "50%",
							}}
						/>
					)}
					<Addbutton />
				</div>
			</span>
			<input type="file" accept="image/*" ref={imageRef} hidden onChange={fileImageSaver} />
		</div>
	)
}

const DummyImageComponent = ( {
	property,
}: {
	property: keyof PatientDialogState["individualPhoto"]
} ) => {
	return (
		<span className="d-flex" style={{ height: "10rem", width: "10rem" }}>
			<div className="card text-bg-secondary" style={{ width: "10rem" }}>
				<img
					alt={photosLibrary[ property ]}
					src={photosLibrary[ property ]}
					className="img-fluid"
					style={{ height: "-webkit-fill-available" }}
				/>
			</div>
		</span>
	)
}
