import React, { cloneElement, isValidElement, ReactNode } from "react"
import { userSessionStore } from "../../store/userSessionStore"
import { TaskAssignedRoles } from "../../utils/appConstants"
import {
	GENERIC_PERMISSIONS,
	GENERIC_PERMISSIONS_TYPE,
} from "../../utils/genericPermissionConstants"

interface CheckPermissionProps {
	genericLabel: keyof GENERIC_PERMISSIONS_TYPE
	children: React.ReactNode
}

interface GenericPermissionProps {
	genericLabel: keyof GENERIC_PERMISSIONS_TYPE
	children: React.ReactNode
}
interface CheckPermissions {
	disallowed?: boolean
	children: React.ReactNode
}

type timelinePermissionProps = {
	assignedTo: string
	children: React.ReactNode
}

// export const CheckPermission: React.FC<CheckPermissionProps> = ( { genricLabel, ...props } ) => {
// 	const { user } = userSessionStore()
// 	const userHasPermission = hasPermission( user, GENERIC_PERMISSIONS[ genricLabel ] )
// 	return <>{userHasPermission ? props.children : null}</>
// }

export const CheckDoctor: React.FC<CheckPermissions> = ( { disallowed, ...props } ) => {
	const { user } = userSessionStore()
	return <>{user.isDoctor ? props.children : null}</>
}
export const CheckAdmin: React.FC<CheckPermissions> = ( { disallowed, ...props } ) => {
	const { user } = userSessionStore()

	return <>{user.isAdmin ? props.children : null}</>
}
export const CheckOperator: React.FC<CheckPermissions> = ( { disallowed, ...props } ) => {
	const { user } = userSessionStore()
	if ( disallowed ) return <>{user.isAdmin || user.isDoctor ? props.children : null}</>
	return <>{!user.isAdmin && !user.isDoctor ? props.children : null}</>
}

export const CheckTimelinePermissions = ( { assignedTo, ...props }: timelinePermissionProps ) => {
	const { user } = userSessionStore()
	const userType = user.isDoctor
		? TaskAssignedRoles.DOCTORS
		: user.isAdmin
			? assignedTo
			: TaskAssignedRoles.OTHERS
	return assignedTo === userType ? <>{props.children}</> : null
}

type GenericPermissionsKeys = keyof typeof GENERIC_PERMISSIONS

interface CheckPermissionProps {
	genericLabel: GenericPermissionsKeys
	children: ReactNode
}

export const CheckGenericPermission: React.FC<CheckPermissionProps> = ( {
	genericLabel,
	children,
} ) => {
	const { user } = userSessionStore()
	const permissions: GENERIC_PERMISSIONS_TYPE = user?.genericPermissions || {}
	const permissionKey = GENERIC_PERMISSIONS[ genericLabel ]
	const permissionLevel = permissionKey
		? permissions[ permissionKey as keyof GENERIC_PERMISSIONS_TYPE ]
		: null

	if ( user.isAdmin ) return <>{children}</>
	if ( permissionLevel === undefined ) {
		console.warn( `Permission key "${permissionKey}" not found for label "${genericLabel}"` )
		return null
	}
	if ( permissionLevel === 0 ) return null
	if ( permissionLevel === 1 ) {
		console.log( "permissionLevel", permissionLevel )
		return (
			<>
				{React.Children.map( children, ( child ) => {
					if ( isValidElement( child ) ) {
						const { onClick, ...restProps } = child.props

						return cloneElement( child, {
							onClick: ( e: React.MouseEvent ) => {
								e.preventDefault()
								console.log( "Intercepted onClick at permission level 1" )

								if ( onClick ) {
									onClick( e )
								}
							},
							className: `${child.props.className} disabled`,
							style: { pointerEvents: "none" },
							...restProps,
						} )
					}
					return child
				} )}
			</>
		)
	}

	if ( permissionLevel === 2 ) return <>{children}</>
	console.warn( `Unsupported permission level for label: "${genericLabel}"` )
	return null
}
