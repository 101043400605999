import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"

export const EditTrash = ( { editCallback, deleteCallBack, editIcon, trashIcon }: EditTrash ) => {
	return (
		<span className="d-flex align-items-center">
			<FontAwesomeIcon {...editIcon} className="celebActionIcon mx-2" onClick={editCallback} />
			<FontAwesomeIcon {...trashIcon} className="celebActionIcon mx-2" onClick={deleteCallBack} />
		</span>
	)
}

interface EditTrash {
	editCallback: () => void
	deleteCallBack: () => void
	trashIcon: FontAwesomeIconProps
	editIcon: FontAwesomeIconProps
}
