import React from "react"
import { CloseButton, Form } from "react-bootstrap"
import addbutton from "../../../assets/img/add-button.svg"
import { getFileReadUrlQuery } from "../../../services/CelebMedicalFile.Service"
import { getPatientTreatmentFilesQuery } from "../../../services/CelebTreatmentFiles.Service"
import { PatientDialogState, usePatientDialogStore } from "../../../store/patientDailogStore"
import PatientPhotoComposite from "./PatientPhotoComposite"
import { photosLibrary } from "./defaultFileShowCase"

const PatientPhotosTab = () => {
	return (
		<React.Suspense>
			<PatientPhotosTabImpl />
		</React.Suspense>
	)
}
const PatientPhotosTabImpl = () => {
	const PatientDialog = usePatientDialogStore()
	const [ isPhotoCondition, setIsPhotoCondition ] = React.useState<"composite" | "individual">(
		"individual",
	)
	const { data: defaultTreatmentFiles } = getPatientTreatmentFilesQuery( PatientDialog.typesEditOpen )

	defaultTreatmentFiles.forEach( ( file: fileResult ) => {
		if ( file.fileCategory == "patient_photo" ) {
			const imageUri = getFileReadUrlQuery( file.fileUri )

			photosLibrary[ file.fileSubCategory ] = imageUri.data
		}
	} )

	return (
		<React.Fragment>
			<div className="d-flex gap-5">
				<Form.Check
					reverse
					type="radio"
					defaultChecked
					label="Individual"
					name="celebrace"
					onChange={() => setIsPhotoCondition( "individual" )}
				/>
				<Form.Check
					reverse
					type="radio"
					label="Composite"
					name="celebrace"
					onChange={() => setIsPhotoCondition( "composite" )}
				/>
			</div>
			{isPhotoCondition == "composite" ? (
				<PatientPhotoComposite />
			) : (
				<ImageGrid style={{ height: "10rem", width: "10rem" }} />
			)}
		</React.Fragment>
	)
}

export const ImageGrid = ( { style }: { style: React.CSSProperties } ) => {
	return (
		<section className="d-flex justify-content-center">
			<div>
				<span className="d-flex gap-3 my-2">
					<CardComponent property="patient_photo_face_side" style={style} />
					<CardComponent property="patient_photo_face_front" style={style} />
					<CardComponent property="patient_photo_face_front_teeth" style={style} />
				</span>
				<span className="d-flex gap-3 my-2">
					<CardComponent property="patient_photo_jaw_upper" style={style} />
					<div className="card text-bg-secondary" style={style}>
						<img alt="" src="" className="img-fluid" style={{ height: "-webkit-fill-available" }} />
					</div>
					<CardComponent property="patient_photo_jaw_lower" style={style} />
				</span>
				<span className="d-flex gap-3 my-2">
					<CardComponent property="patient_photo_jaw_right" style={style} />
					<CardComponent property="patient_photo_jaw_front" style={style} />
					<CardComponent property="patient_photo_jaw_left" style={style} />
				</span>
			</div>
		</section>
	)
}

export const CardComponent = ( {
	property,
	style,
}: {
	style: React.CSSProperties
	property: keyof PatientDialogState["individualPhoto"]
} ) => {
	const imageRef = React.useRef<HTMLInputElement | null>( null )
	const { individualPhoto, setIndividualPhoto } = usePatientDialogStore()
	const fileImageSaver = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		e.preventDefault()
		if ( e.currentTarget.files ) {
			setIndividualPhoto( property, e.currentTarget.files[ 0 ] )
		}
	}
	const img = individualPhoto[ property ]
	const check = photosLibrary[ property ]
	return (
		<div>
			<span className="d-flex" style={style}>
				<div
					className="card text-bg-secondary"
					onClick={() => {
						check.length >= 3 ? imageRef.current?.click() : ""
					}}
					style={{ width: "10rem" }}
				>
					<img
						alt=""
						src={img ? URL.createObjectURL( img ) : photosLibrary[ property ]}
						className="img-fluid"
						style={{ height: "-webkit-fill-available" }}
					/>
					{img && (
						<CloseButton
							onClick={( e ) => {
								e.preventDefault()
								setIndividualPhoto( property, null )
							}}
							style={{
								position: "absolute",
								top: "0px",
								right: "0px",
								backgroundColor: "#E3E0F0",
								borderRadius: "50%",
							}}
						/>
					)}
					<Addbutton />
				</div>
			</span>
			<input type="file" accept="image/*" ref={imageRef} hidden onChange={fileImageSaver} />
		</div>
	)
}

export const Addbutton = () => {
	return (
		<div
			className="card-img-overlay"
			style={{
				display: "flex",
				justifyContent: "end",
				height: "-webkit-fill-available",
				alignItems: "end",
				padding: "6px",
			}}
		>
			<img src={addbutton} alt="" />
		</div>
	)
}

export default PatientPhotosTab
export interface fileResult {
	id: string
	fileType: string
	fileUri: any
	originalFileName: string
	fileCategory: string
	fileSubCategory: string
	patientId: string
	fileTreatmentPlanId: string
	doctorId: string
	createdAt: string
	updatedAt: string
}
