import React from "react"
import { CloseButton } from "react-bootstrap"
import { getFileReadUrlQuery } from "../../../services/CelebMedicalFile.Service"
import { getPatientTreatmentFilesQuery } from "../../../services/CelebTreatmentFiles.Service"
import { PatientDialogState, usePatientDialogStore } from "../../../store/patientDailogStore"
import { photosLibrary } from "../editPatients-v2/defaultFileShowCase"
import { Addbutton, fileResult } from "./PatientPhotos.tab"

const PatientXrayTab = () => {
	const { replaceOpen } = usePatientDialogStore()
	const { data: defaultTreatmentFiles } = getPatientTreatmentFilesQuery( replaceOpen )

	defaultTreatmentFiles.forEach( ( file: fileResult ) => {
		if ( file.fileCategory == "patient_xray" ) {
			const imageUri = getFileReadUrlQuery( file.fileUri )
			photosLibrary[ file.fileSubCategory ] = imageUri.data
		}
	} )
	return (
		<div className="d-flex justify-content-center gap-5 my-5">
			<CardComponent property="patient_xray_skull" />
			<CardComponent property="patient_xray_teeth" />
		</div>
	)
}

export default PatientXrayTab

const CardComponent = ( { property }: { property: keyof PatientDialogState["xRays"] } ) => {
	const imageRef = React.useRef<HTMLInputElement | null>( null )
	const { xRays, setXRays } = usePatientDialogStore()
	const fileImageSaver = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		e.preventDefault()
		if ( e.currentTarget.files ) {
			setXRays( property, e.currentTarget.files[ 0 ] )
		}
	}
	const img = xRays[ property ]
	const check = photosLibrary[ property ]
	return (
		<div>
			<span className="d-flex" style={{ height: "10rem", width: "10rem" }}>
				<div
					className="card text-bg-secondary"
					onClick={( x ) => {
						check.length >= 3 ? imageRef.current?.click() : ""
					}}
					style={{ width: "10rem" }}
				>
					<img
						alt=""
						src={img ? URL.createObjectURL( img ) : photosLibrary[ property ]}
						className="img-fluid"
						style={{ height: "-webkit-fill-available" }}
					/>
					{img && (
						<CloseButton
							onClick={( e ) => {
								e.preventDefault()
								setXRays( property, null )
							}}
							style={{
								position: "absolute",
								top: "0px",
								right: "0px",
								backgroundColor: "#E3E0F0",
								borderRadius: "50%",
							}}
						/>
					)}
					<Addbutton />
				</div>
			</span>
			<input type="file" ref={imageRef} hidden onChange={fileImageSaver} />
		</div>
	)
}
