import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CSSProperties, useEffect, useState } from "react"
import { Form } from "react-bootstrap"

const SearchInput = ( {
	callback,
	style,
	placeholder,
}: {
	placeholder?: string
	callback: ( search: string ) => void
	style?: CSSProperties
} ) => {
	const [ search, setSearch ] = useState<string>( "" )
	const [ timer, setTimer ] = useState<NodeJS.Timeout | null>( null )

	useEffect( () => {
		if ( timer ) {
			clearTimeout( timer )
		}
		const newTimer = setTimeout( () => {
			callback( search )
		}, 1000 )
		setTimer( newTimer )
	}, [ search ] )

	return (
		<div style={{ position: "relative", ...style }}>
			<Form.Control
				type="search"
				placeholder={placeholder ? placeholder : "Search ...."}
				onChange={( e ) => setSearch( e.currentTarget.value )}
				style={{ paddingRight: "2.5rem" }}
			/>
			<FontAwesomeIcon
				icon={faSearch}
				style={{
					position: "absolute",
					right: "0.5rem",
					top: "50%",
					transform: "translateY(-50%)",
					pointerEvents: "none",
					color: "#6c757d",
				}}
			/>
		</div>
	)
}

export default SearchInput
