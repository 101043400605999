import { faClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { Button, Form, Modal, Nav, Navbar, Table } from "react-bootstrap"
import { FieldErrors, FormProvider, useForm, useFormContext } from "react-hook-form"
import { useLocation } from "react-router"
import Select from "react-select"
import { useListPermissionsQuery } from "../../../services/CelebGenericPermission.Service"
import {
	RoleCreationSchema,
	RoleCreationSchemaType,
	TaskPermissions,
	useGetRoleMutaion,
	useListRolesQuery,
	useSaveRoleMutaion,
} from "../../../services/CelebRole.Service"
import {
	useListServicesQuery,
	useServicefetchDetails,
} from "../../../services/CelebService.Service"
import {
	GenericPermissionType,
	PermissionType,
	TaskPermissionType,
	useAccountsDialogStore,
} from "../../../store/accountsDialogStore"
import SearchInput from "../../form/SearchInput"
import { clearHash, sortForReactSelect, SortReactSelectType } from "../../utlity/GenricFunctions"
import { BoundControlTypes } from "./AccountFormFields"

const AccountTypesAddDialog = () => {
	const methods = useForm<RoleCreationSchemaType>( {
		resolver: zodResolver( RoleCreationSchema ),
		mode: "all",
	} )
	const { typesCreateOpen, resetState } = useAccountsDialogStore()
	const clearHashClear = clearHash()

	return (
		<React.Suspense>
			<FormProvider {...methods}>
				<Modal
					show={typesCreateOpen}
					size="xl"
					backdrop="static"
					onHide={() => {
						resetState()
						methods.reset()
						clearHashClear()
					}}
				>
					<Navbar style={{ backgroundColor: "#d9d9d9" }} as={Modal.Header} closeButton>
						<Nav>
							<Nav.Link className="nav-link-opposite mt-1" active={true}>
								Accounts
							</Nav.Link>
						</Nav>
					</Navbar>
					<AccountTabImpl />
				</Modal>
			</FormProvider>
		</React.Suspense>
	)
}

const AccountTabImpl = () => {
	const method = useFormContext<RoleCreationSchemaType>()
	const {
		data: { data: roles, total: total },
	} = useListRolesQuery()
	const [ rolesOption, setRolesOption ] = React.useState<SortReactSelectType>( [] )
	const { mutateAsync: getRoleInformation } = useGetRoleMutaion()
	const { mutateAsync: saveRole } = useSaveRoleMutaion()
	const { setPermissions, setGenericPermissions, setServiceOpted, resetState } =
		useAccountsDialogStore()

	React.useEffect( () => {
		setRolesOption( sortForReactSelect( roles, "id", "name" ) )
		method.setValue( "specialRole", "none" )
	}, [] )

	const setRoleInformatopm = async ( value: any ) => {
		const data = await getRoleInformation( value.value )
		const services: string[] = []
		const mappedPermissions = data.taskPermissions.map( ( item: TaskPermissionType ) => {
			services.includes( item.roleTaskPermissionTask.serviceId ) ||
				services.push( item.roleTaskPermissionTask.serviceId )
			return {
				taskId: item.taskId,
				permission: item.permission,
			}
		} )
		const genericPermissionsMapped = data.genericPermissions.map(
			( item: { genericPermissionId: string; permission: string } ) => ( {
				permissionId: item.genericPermissionId,
				permission: item.permission,
			} ),
		)
		setServiceOpted( services )
		setPermissions( mappedPermissions )
		setGenericPermissions( genericPermissionsMapped )
	}
	const onSave = ( value: RoleCreationSchemaType ) => {
		saveRole( value )
		method.reset()
		resetState()
	}
	const onError = ( error: FieldErrors<RoleCreationSchemaType> ) => {
		console.log( error )
	}
	return (
		<React.Fragment>
			<Modal.Body>
				<Form onSubmit={method.handleSubmit( onSave, onError )}>
					<section className="d-flex w-100 justify-content-between mb-3">
						<span className="w-25">
							<BoundControlTypes
								name="name"
								label="Account Role Name"
								placeholder="Enter Account Name"
							/>
						</span>
						<span className="w-25">
							<Form.Label>Start From</Form.Label>
							<Select options={rolesOption} onChange={setRoleInformatopm}></Select>
						</span>
						<span className="auto">
							<Form.Label>Account Type</Form.Label>
							<Form.Check
								defaultChecked={false}
								defaultValue={"none"}
								onChange={( e ) =>
									method.setValue( "specialRole", e.currentTarget.checked ? "doctor" : "none" )
								}
								label={"is Doctor"}
							></Form.Check>
						</span>
						<span>
							<Form.Label>Account Status</Form.Label>
							<Form.Check defaultChecked={true} label={"Active"} {...method.register( "status" )} />
						</span>
						<span>
							<Button className="mt-4" type="submit">
								Save Account Type
							</Button>
						</span>
					</section>

					<ExpandableNavbarTabs />
				</Form>
			</Modal.Body>
		</React.Fragment>
	)
}

export const ExpandableNavbarTabs = () => {
	const { data: servicesData } = useListServicesQuery()
	const [ service, setService ] = React.useState<{ value: string; label: string }[]>( [] )
	const { serviceOpted } = useAccountsDialogStore()
	const [ servicesList, setServiceList ] = React.useState<{ value: string; label: string }[]>( [] )

	React.useEffect( () => {
		const options = sortForReactSelect( servicesData.services, "id", "name" )
		setServiceList( options )
		window.location.href = "#genericpermissions"
	}, [] )

	React.useEffect( () => {
		const sortedService = servicesData?.services.filter( ( x ) => serviceOpted.includes( x.id ) )
		const sortedService2 = servicesData?.services.filter( ( x ) => !serviceOpted.includes( x.id ) )
		const options = sortForReactSelect( sortedService2, "id", "name" )
		const options2 = sortForReactSelect( sortedService, "id", "name" )
		setServiceList( options )
		setService( options2 )
	}, [ serviceOpted ] )

	const addServiceToList = ( e: any ) => {
		const check = service.find( ( x ) => x.value == e.value )
		const updateService = servicesList.filter( ( x ) => x.value != e.value )
		if ( check?.label ) return null
		setService( ( prev ) => [ ...prev, { value: e.value, label: e.label + "" } ] )
		setServiceList( updateService )
	}

	const removeFromService = (
		e: React.MouseEvent<SVGSVGElement, MouseEvent>,
		x: { value: string; label: string },
	) => {
		e.preventDefault()
		setService( ( prev ) => prev.filter( ( item ) => item.value !== x.value ) )
		setServiceList( ( prev ) => [ ...prev, x ] )
		window.location.href = "#genericpermissions"
	}

	return (
		<section>
			<Navbar as={Modal.Header}>
				<Nav
					variant="tabs"
					defaultActiveKey="#genericpermissions"
					className="align-items-end mt-1 w-100"
				>
					<Nav.Link href="#genericpermissions" className="nav-link">
						Genric Permissisons
					</Nav.Link>
					{service.map( ( x ) => {
						return (
							<Nav.Link className="nav-link" key={x.value} href={"#" + x.value}>
								{x?.label}

								<FontAwesomeIcon
									icon={faClose}
									onClick={( e ) => removeFromService( e, x )}
								></FontAwesomeIcon>
							</Nav.Link>
						)
					} )}

					<Select options={servicesList} onChange={addServiceToList} className="mx-1"></Select>
				</Nav>
			</Navbar>
			<PermissionsShowCase />
		</section>
	)
}

export const PermissionsShowCase = () => {
	const location = useLocation().hash.substring( 1 )
	const { data: permissionData } = useListPermissionsQuery()
	const { data: serviceData } = useServicefetchDetails( location )
	const genericPermissionsList =
		location == "genericpermissions" ? permissionData : serviceData.tasks
	const method = useFormContext<RoleCreationSchemaType>()
	const {
		permissions,
		addPermission,
		removePermission,
		genericPermissions,
		addGenericPermissions,
		removeGenericPermissions,
	} = useAccountsDialogStore()

	const [ query, setQuery ] = React.useState( "" )
	const filteredPermissions = genericPermissionsList?.filter( ( task: TaskPermissions ) =>
		task.name.toLowerCase().includes( query.toLowerCase() ),
	)

	React.useEffect( () => savePermissions(), [ genericPermissions, permissions ] )
	const handlePermissionChange = ( taskId: string, value: number ) => {
		if ( location == "genericpermissions" ) {
			const genericPermission = { permissionId: taskId, permission: value }
			removeGenericPermissions( taskId )
			addGenericPermissions( genericPermission )
		} else {
			const newPermission = { taskId, permission: value }
			removePermission( taskId )
			addPermission( newPermission )
		}
	}

	const savePermissions = () => {
		method.setValue( "genericPermissions", genericPermissions )
		method.setValue( "permissions", permissions )
	}

	const getPermissionValue = ( taskId: string ): number | undefined => {
		if ( location == "genericpermissions" ) {
			const permission = genericPermissions.find(
				( p: GenericPermissionType ) => p.permissionId === taskId,
			)
			return permission?.permission
		} else {
			const permission = permissions.find( ( p: PermissionType ) => p.taskId === taskId )
			return permission?.permission
		}
	}

	return (
		<React.Fragment>
			<div>
				<Table striped bordered className="table">
					<thead>
						<tr>
							<th scope="col" style={{ width: "17rem" }}>
								<span
									className="d-inline-flex justify-content-center gap-2 align-items-end"
									style={{ width: "100%" }}
								>
									Task{" "}
									<SearchInput callback={( e ) => setQuery( e )} placeholder="Search Permissions" />
								</span>
							</th>
							<th scope="col">No View</th>
							<th scope="col">View Only</th>
							<th scope="col">Edit</th>
						</tr>
					</thead>
					<tbody>
						{filteredPermissions?.map( ( task: any, index: number ) => {
							const currentPermission = getPermissionValue( task.id ) ?? 0
							return (
								<tr key={index}>
									<td className="font-weight-bold">{task.name}</td>
									<td>
										<Form.Check
											type="radio"
											label=""
											name={task.name}
											id={`task-${index}-no-view`}
											checked={currentPermission === 0}
											onChange={() => handlePermissionChange( task.id, 0 )}
										/>
									</td>
									<td>
										<Form.Check
											type="radio"
											label=""
											name={task.name}
											id={`task-${index}-view-only`}
											checked={currentPermission === 1}
											onChange={() => handlePermissionChange( task.id, 1 )}
										/>
									</td>
									<td>
										<Form.Check
											type="radio"
											label=""
											name={task.name}
											id={`task-${index}-edit`}
											checked={currentPermission === 2}
											onChange={() => handlePermissionChange( task.id, 2 )}
										/>
									</td>
								</tr>
							)
						} )}
					</tbody>
				</Table>
			</div>
		</React.Fragment>
	)
}

export default AccountTypesAddDialog

export const SpecialRoleOptions: SortReactSelectType = [
	{ label: "doctor", value: "doctor" },
	{ label: "operator", value: "none" },
]
