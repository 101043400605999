import React from "react"
import { Button, Card, ListGroup, Modal, Nav, Navbar } from "react-bootstrap"
import { useSearchParams } from "react-router-dom"
import { teethIdJSON } from "../../../services/CelebPatient.Service"
import { getPatientByIdQuery } from "../../../services/CelebTreatment.Service"
import { useSidebarStore } from "../../../store/sideBarStore"
import CelebButtonTootheNumber from "../../buttons/CelebButtonToothNumber"

const ReplaceOrderDetails = () => {
	const [ searchParams, setSearchParams ] = useSearchParams()
	const sidebar = useSidebarStore().sideBarPatientData.PatientData
	const patientId = searchParams.get( "PatientId" )
	const { data: toothData } = getPatientByIdQuery( patientId )
	const [ replacementDetails, setReplaceDetails ] = React.useState( false )

	return (
		<div style={{ height: "100%", backgroundColor: "#d9d9d9", width: "100%" }}>
			<Card style={{ backgroundColor: "#d9d9d9", border: "none" }}>
				<Card.Header className="text-center">Replace Order</Card.Header>
				<ListGroup variant="flush">
					<ListGroup.Item className="listCard">
						<p>Upper Wire</p>
						<p>
							{toothData.reaplceOrderDetails &&
								toothData.reaplceOrderDetails.lowerWires.map( ( x: string ) => <text>{x}</text> )}
						</p>
					</ListGroup.Item>
					<ListGroup.Item className="listCard">
						<p>Lower Wire</p>
						<p>
							{toothData.reaplceOrderDetails &&
								toothData.reaplceOrderDetails.lowerWires.map( ( x: string ) => <text>{x}</text> )}
						</p>
					</ListGroup.Item>
					<ListGroup.Item className="listCard">
						<Button onClick={() => setReplaceDetails( true )}>Show Replacement Details</Button>
					</ListGroup.Item>
				</ListGroup>
			</Card>

			{sidebar.isReplacementOrder && (
				<ReplaceOrderDetailsDailog
					replacementDetails={replacementDetails}
					setReplaceDetails={setReplaceDetails}
				/>
			)}
		</div>
	)
}

const ReplaceOrderDetailsDailog = ( {
	replacementDetails,
	setReplaceDetails,
}: {
	replacementDetails: boolean
	setReplaceDetails: React.Dispatch<React.SetStateAction<boolean>>
} ) => {
	if ( replacementDetails == false ) return null
	const [ searchParams, setSearchParams ] = useSearchParams()
	const patientId = searchParams.get( "PatientId" )
	const { data: toothData } = getPatientByIdQuery( patientId )
	const itterativeCheck = { ...toothData.reaplceOrderDetails.braces }

	return (
		<Modal show={replacementDetails} size="lg" onHide={() => setReplaceDetails( false )}>
			<Navbar as={Modal.Header} id="navbarScroll" closeButton>
				<Nav variant="tabs" defaultActiveKey="#PatientInfo" className="d-flex mt-2 me-auto">
					<Nav.Link active>Replacement Order</Nav.Link>
				</Nav>
			</Navbar>
			<Modal.Body>
				<div className="d-grid justify-content-center">
					<span
						className="d-flex justify-content-between"
						style={{ color: "#87ceeb", fontWeight: "bold", fontSize: "12px" }}
					>
						<p>L</p>
						<p>R</p>
					</span>
					<div className="d-flex justify-content-between w-100">
						<div className="d-flex">
							{Object.keys( teethIdJSON.upperSection.left )
								.reverse()
								.map( ( toothId ) => {
									const toothNumber = Number( toothId[ 2 ] )
									const active = itterativeCheck[ toothId ]
									const disabled = itterativeCheck.disabledUpper
									return (
										<div key={toothId}>
											<CelebButtonTootheNumber
												tootheNumber={toothNumber}
												active={active}
												disabled={disabled}
											/>
										</div>
									)
								} )}
							<div className="vr"></div>
							{Object.keys( teethIdJSON.upperSection.right ).map( ( toothId ) => {
								const toothNumber = Number( toothId[ 2 ] )
								const active = itterativeCheck[ toothId ]
								const disabled = itterativeCheck.disabledUpper
								return (
									<div key={toothId}>
										<CelebButtonTootheNumber
											tootheNumber={toothNumber}
											active={active}
											disabled={disabled}
										/>
									</div>
								)
							} )}
						</div>
					</div>
					<hr className="hr" />

					<div className="d-flex justify-content-between w-100">
						<div className="d-flex">
							{Object.keys( teethIdJSON.lowerSection.left )
								.reverse()
								.map( ( toothId ) => {
									const toothNumber = Number( toothId[ 2 ] )
									const active = itterativeCheck[ toothId ]
									const disabled = itterativeCheck.disabledLower
									return (
										<div key={toothId}>
											<CelebButtonTootheNumber
												tootheNumber={toothNumber}
												active={active}
												disabled={disabled}
											/>
										</div>
									)
								} )}
							<div className="vr"></div>
							{Object.keys( teethIdJSON.lowerSection.right ).map( ( toothId ) => {
								const toothNumber = Number( toothId[ 2 ] )
								const active = itterativeCheck[ toothId ]
								const disabled = itterativeCheck.disabledLower
								return (
									<div key={toothId}>
										<CelebButtonTootheNumber
											tootheNumber={toothNumber}
											active={active}
											disabled={disabled}
										/>
									</div>
								)
							} )}
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default ReplaceOrderDetails
