export type TASKS_TYPE = {
	id: string
	name: string
	description: any
	writeNote: boolean
	uploadFile: boolean
	checkmark: boolean
	paymentStage: boolean
	replaceOrderTask: boolean
	daysToComplete: any
	previousTasks: string[]
	rollBackToTask: string[]
	isActive: any
	taskAssignedRole: string
	providerCategoryId: string
	consumerCategoryId: string
	serviceId: string
	deleted: boolean
	deletedAt: any
	deletedBy: any
	createdAt: string
	updatedAt: string
}
const taskMapping: any = {}

const dependsOn = ( task: TASKS_TYPE, level: TASKS_TYPE[] ) =>
	level.some( ( z ) => task.previousTasks.includes( z.id ) )

export const SortTasksAccordingly = ( tasks: TASKS_TYPE[] ) => {
	const assignTasksToKeys = () => {
		let level = 0
		let remainingTasks = tasks.slice()

		while ( remainingTasks.length > 0 ) {
			const currentKey = `key${level}`
			taskMapping[ currentKey ] = []

			remainingTasks = remainingTasks.filter( ( task ) => {
				if ( level === 0 && task.previousTasks.length === 0 ) {
					taskMapping[ currentKey ].push( task )
					return false
				}

				const previousKey = `key${level - 1}`
				if ( level > 0 && dependsOn( task, taskMapping[ previousKey ] ) ) {
					taskMapping[ currentKey ].push( task )
					return false
				}
				return true
			} )

			level++
		}
	}

	assignTasksToKeys()

	const allTasksInOrder = Object.keys( taskMapping ).reduce( ( acc, key ) => {
		return acc.concat( taskMapping[ key ] )
	}, [] )

	return allTasksInOrder
}
