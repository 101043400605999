import { Toast } from "react-bootstrap"
import { userSessionStore } from "../../store/userSessionStore"

export const ToastPopup = () => {
	const { showToast, setShowToast } = userSessionStore()
	return (
		<Toast
			show={showToast.visible}
			style={{ position: "fixed", top: "1rem", right: "1rem", zIndex: 1000 }}
			autohide={true}
			onClose={() => setShowToast( "visible", false )}
			bg={showToast.status.toLowerCase()}
		>
			<Toast.Header>
				<img
					src="/img_logo.jpg"
					className="rounded me-2 "
					style={{ width: "15px", height: "15px" }}
					alt=""
				/>
				<strong className="me-auto">Celebrace</strong>
			</Toast.Header>
			<Toast.Body className="text-white">{showToast.message}</Toast.Body>
		</Toast>
	)
}
