const Financials = () => {
	return (
		<div
			style={{
				backgroundColor: "#d9d9d9",
				width: "-webkit-fill-available",
				borderRadius: "10px",
				overflow: "auto",
			}}
		>
			financials comming soon
		</div>
	)
}

export default Financials
