import { useMutation, useSuspenseQuery } from "@tanstack/react-query"
import axios from "axios"
import { z } from "zod"
import { queryClient } from "../providers/QueryProvider"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"
import { UseListUsersQueryProps } from "./CelebUser.Service"

export class CelebRoleService {
	static _instance: CelebRoleService = new CelebRoleService()

	private constructor() {
		CelebRoleService._instance = this
	}

	static getInstance(): CelebRoleService {
		return CelebRoleService._instance
	}

	async getAllRoles( { limit, pageOffset, query }: UseListUsersQueryProps ) {
		let queryParam = `?offset=${pageOffset ?? 0}`
		if ( query && query.length > 0 ) {
			queryParam += `&search=${query}`
		}
		if ( limit ) {
			queryParam += `&limit=${limit}`
		}
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.ROLES}/${queryParam}`

		return await axios.get( requestUrl )
	}

	async getDetails( roleId: string ) {
		const requestUrl = `${constants.ROLES}/${roleId}`

		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}

	async update( roleId: string | null, details: RoleCreationSchemaType ) {
		const requestUrl = `${constants.ROLES}/${roleId}`

		return await CelebRequestGeneratorService.processPutRequest( requestUrl, details )
	}

	async createRole( requestPayload: RoleCreationSchemaType ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.ROLES}`

		return await axios.post( requestUrl, requestPayload )
	}
	async deleterole( id: string ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.ROLES}/${id}`
		return await axios.delete( requestUrl )
	}
}

export const listRolesQueryKeyFn = ( props?: UseListUsersQueryProps ) =>
	[ "list", constants.ROLES, props ].filter( Boolean )
export const getRoleQueryKeyFn = ( id: string ) => [ "list", constants.ROLES, id ]

export const useListRolesQuery = ( props?: UseListUsersQueryProps ) =>
	useSuspenseQuery( {
		queryKey: listRolesQueryKeyFn( props ),
		queryFn: () =>
			CelebRoleService.getInstance()
				.getAllRoles( props ? props : {} )
				.then( ( res ) => ( { data: res.data.data, total: res.headers[ "x-total-count" ] } ) )
				.then( ( { data, total } ) => {
					return {
						data: data,
						total: total,
					}
				} ),
	} )

export const useGetRoleQuery = ( id: string ) =>
	useSuspenseQuery( {
		queryKey: getRoleQueryKeyFn( id ),
		queryFn: () => CelebRoleService.getInstance().getDetails( id ),
	} )

export const useGetRoleMutaion = () =>
	useMutation( {
		mutationFn: ( id: string ) =>
			CelebRoleService.getInstance()
				.getDetails( id )
				.then( ( res ) => res.data.data )
				.then( ( data ) => data ),
	} )

export const useCurrentRoleDetailsQuery = ( id: string ) => {
	return useMutation( {
		mutationFn: () =>
			CelebRoleService.getInstance()
				.getDetails( id )
				.then( ( res ) => res.data.data )
				.then( ( data: Account_Type_Response ) => data ),
	} )
}

export const useSaveRoleMutaion = () =>
	useMutation( {
		mutationFn: ( value: RoleCreationSchemaType ) =>
			CelebRoleService.getInstance()
				.createRole( value )
				.then( ( res ) => res.data.data )
				.then( ( data ) => data ),
		onSuccess: () => {
			queryClient.invalidateQueries( { queryKey: listRolesQueryKeyFn(), exact: false } )
		},
	} )

export const useEditRoleMutaion = () =>
	useMutation( {
		mutationFn: ( data: { roleId: string | null; value: RoleCreationSchemaType } ) =>
			CelebRoleService.getInstance()
				.update( data.roleId, data.value )
				.then( ( res ) => res.data.data )
				.then( ( data ) => data ),
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listRolesQueryKeyFn() } )
		},
	} )

export const useDeleteRoleMutation = () =>
	useMutation( {
		mutationFn: ( id: string ) => CelebRoleService.getInstance().deleterole( id ),
		onSettled: () => {
			queryClient.invalidateQueries( { queryKey: listRolesQueryKeyFn() } )
		},
	} )

export const SpecialRoleEnumSchema = z.enum( [ "none", "admin", "doctor" ] )

export const RoleSchema = z.object( {
	id: z.string(),
	name: z.string(),
	description: z.string().nullable(),
	status: z.boolean(),
	specialRole: SpecialRoleEnumSchema,
	deleted: z.boolean(),
	deletedAt: z.string().datetime().nullable(),
	deletedBy: z.string().nullable(),
	createdAt: z.string().datetime(),
	updatedAt: z.string().datetime(),
} )

export type RoleType = z.infer<typeof RoleSchema>

export const TaskPermissionSchema = z.object( {
	id: z.string(),
	permission: z.number(),
	roleId: z.string(),
	taskId: z.string(),
} )

export const RoleCreationSchema = z.object( {
	name: z.string().min( 1, { message: "required" } ),
	status: z.boolean(),
	permissions: z.array( z.object( { taskId: z.string(), permission: z.number() } ) ).optional(),
	specialRole: SpecialRoleEnumSchema,
	genericPermissions: z
		.array( z.object( { permissionId: z.string(), permission: z.number() } ) )
		.optional(),
} )

export interface ResponseTypeRole {
	createdAt: string
	deleted: boolean
	deletedAt: string | null
	deletedBy: string | null
	description: string | null
	id: string
	name: string
	specialRole: string
	status: boolean
	updatedAt: string
}

export interface Account_Type_Response {
	id: string
	name: string
	description: any
	status: boolean
	deleted: boolean
	deletedAt: any
	deletedBy: any
	specialRole: string
	createdAt: string
	updatedAt: string
	taskPermissions: any[]
	genericPermissions: any[]
}

export const TaskPermissions = z.object( {
	createdAt: z.string(),
	description: z.string().optional(),
	id: z.string(),
	name: z.string(),
	permissionCode: z.string(),
	updatedAt: z.string(),
} )

export type TaskPermissions = z.infer<typeof TaskPermissions>
export type eachRole = z.infer<typeof RoleCreationSchema>
export type RoleCreationSchemaType = z.infer<typeof RoleCreationSchema>
