import React from "react"

const ErrorBoundaryPage = () => {
	React.useEffect( () => {
		window.localStorage.clear()
		window.location.href = "/"
	}, [] )

	return <div>ErrorBoundary</div>
}

export default ErrorBoundaryPage
