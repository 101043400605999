import React from "react"
import { Outlet } from "react-router"
import { useSearchParams } from "react-router-dom"
import "../../../../assets/styles/components/celebServiceTabs.css"
import LoadingOverlay from "../../../../assets/styles/components/loader/loadingOverlay"
import "../../../../assets/styles/components/navbar.css"
import "../../../../assets/styles/pages/landing.page.css"
import DashBoardNavbar from "../../../../components/navbars/DashBoardNavbar"
import SubNavBar from "../../../../components/navbars/SubNavBar"
import SideNavbar from "../../../../components/navbars/sideNavbar/sideNavbar"

const Dashboard = () => {
	const [ searchParams, setSearchParams ] = useSearchParams()
	const patientId = searchParams.get( "PatientId" )
	return (
		<React.Suspense fallback={<LoadingOverlay />}>
			<DashBoardNavbar />
			<main className="d-flex" style={{ height: "91vh" }}>
				<section
					style={{
						width: "-webkit-fill-available",
						border: " 5px solid #d9d9d9",
						borderRadius: "12px ",
					}}
				>
					<SubNavBar />
					<Outlet />
				</section>
				<aside
					className="w-30"
					style={{ width: "30%", height: "90vh" }}
					hidden={!patientId ? true : false}
				>
					{patientId && <SideNavbar />}
				</aside>
			</main>
		</React.Suspense>
	)
}

export default Dashboard
