import { useMutation, useSuspenseQuery } from "@tanstack/react-query"
import { z } from "zod"
import { queryClient } from "../providers/QueryProvider"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"

import { UseTreatmentServiceQueryProps } from "./CelebTreatmentTask.Service"
export class CelebTreatmentPlanService {
	static _instance: CelebTreatmentPlanService = new CelebTreatmentPlanService()

	private constructor() {
		CelebTreatmentPlanService._instance = this
	}

	static getInstance(): CelebTreatmentPlanService {
		return CelebTreatmentPlanService._instance
	}

	async getDetails( treatmentId: string | null ) {
		const requestUrl = `${constants.TREATMENT_PLAN}/${treatmentId}`

		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}

	async updateStatus( treatmentId: string, status: string ) {
		const requestUrl = `${constants.TREATMENT_PLAN}/status/${treatmentId}/${status}`
		return await CelebRequestGeneratorService.processPutRequest( requestUrl, null )
	}

	async getDoctorTreatments( queryParams: UseTreatmentServiceQueryProps ) {
		const queryStringArray: string[] = []
		Object.keys( queryParams ).forEach( ( data ) => {
			queryStringArray.push( data + "=" + queryParams[ data ] )
		} )

		const queryString = "?" + queryStringArray.join( "&" )
		const requestUrl = `${constants.TREATMENT_PLAN}/me${queryString}`

		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}
}

export const editMutationListFn = ( props?: UseStatusUpdateQueryProps ) =>
	[ "treatment", props ].filter( Boolean )

export const useTreatmentStatusMutation = () =>
	useMutation( {
		mutationFn: ( statusUpdate: { treatmentId: string; status: string } ) =>
			CelebTreatmentPlanService.getInstance().updateStatus(
				statusUpdate.treatmentId,
				statusUpdate.status,
			),
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: [ "treatment" ], exact: false } )
		},
	} )

export const getPatientByIdQuery = ( treatmentId: string | null ) => {
	return useSuspenseQuery( {
		queryKey: [ "PatientData", treatmentId ],
		queryFn: () => {
			return CelebTreatmentPlanService.getInstance()
				.getDetails( treatmentId )
				.then( ( res ) => res.data.data )
				.then( ( res ) => res )
		},
	} )
}

export const useTaskCompleteServicesQuery = ( props: UseTreatmentServiceQueryProps ) =>
	useSuspenseQuery( {
		queryKey: [ "treatment", constants.TREATMENT_TASKS, props ],
		queryFn: () =>
			CelebTreatmentPlanService.getInstance()
				.getDoctorTreatments( props )
				.then( ( res ) => ( { data: res.data.data, total: res.headers[ "x-total-count" ] } ) )
				.then( ( { data, total } ) => {
					return {
						treatment: data,
						total: total,
					}
				} ),
	} )

export const PatientEditSchema = z.object( {
	id: z.string(),
	treatmentTitle: z.null(),
	instruction: z.string(),
	treatmentStart: z.string(),
	treatmentType: z.null(),
	upperBracketSlotSize: z.string(),
	lowerBracketSlotSize: z.string(),
	teethExtraction: z.object( {
		UR1: z.string(),
		UR2: z.string(),
		UR3: z.string(),
		UR4: z.string(),
		UR5: z.string(),
		UR6: z.string(),
		UR7: z.string(),
		UR8: z.string(),
		UL1: z.string(),
		UL2: z.string(),
		UL3: z.string(),
		UL4: z.string(),
		UL5: z.string(),
		UL6: z.string(),
		UL7: z.string(),
		UL8: z.string(),
		LR1: z.string(),
		LR2: z.string(),
		LR3: z.string(),
		LR4: z.string(),
		LR5: z.string(),
		LR6: z.string(),
		LR7: z.string(),
		LR8: z.string(),
		LL1: z.string(),
		LL2: z.string(),
		LL3: z.string(),
		LL4: z.string(),
		LL5: z.string(),
		LL6: z.string(),
		LL7: z.string(),
		LL8: z.string(),
	} ),
	teethBonding: z.object( {
		UR1: z.string(),
		UR2: z.string(),
		UR3: z.string(),
		UR4: z.string(),
		UR5: z.string(),
		UR6: z.string(),
		UR7: z.string(),
		UR8: z.string(),
		UL1: z.string(),
		UL2: z.string(),
		UL3: z.string(),
		UL4: z.string(),
		UL5: z.string(),
		UL6: z.string(),
		UL7: z.string(),
		UL8: z.string(),
		LR1: z.string(),
		LR2: z.string(),
		LR3: z.string(),
		LR4: z.string(),
		LR5: z.string(),
		LR6: z.string(),
		LR7: z.string(),
		LR8: z.string(),
		LL1: z.string(),
		LL2: z.string(),
		LL3: z.string(),
		LL4: z.string(),
		LL5: z.string(),
		LL6: z.string(),
		LL7: z.string(),
		LL8: z.string(),
	} ),
	status: z.string(),
	isReplacementOrder: z.boolean(),
	patientId: z.string(),
	doctorsWithAccess: z.array( z.string() ),
	doctorId: z.string(),
	serviceId: z.string(),
	treatmentPlanDoctorAddressId: z.string(),
	createdAt: z.string(),
	updatedAt: z.string(),
	doctor: z.object( {
		firstName: z.string(),
		lastName: z.string(),
		id: z.string(),
	} ),
	treatmentPlanDoctorAddress: z.object( {
		name: z.string(),
		addressLine1: z.string(),
		addressLine2: z.string(),
		city: z.string(),
		state: z.string(),
		pincode: z.string(),
		id: z.string(),
	} ),
	patient: z.object( {
		firstName: z.string(),
		lastName: z.string(),
		entityId: z.number(),
		id: z.string(),
		dob: z.string(),
		gender: z.string(),
		ethenicity: z.null(),
		email: z.null(),
		phone: z.null(),
	} ),
} )

export type PatientEditSchema = z.infer<typeof PatientEditSchema>
type EditId = string | null

export type UseStatusUpdateQueryProps = {
	status?: number
	message?: string
}
