import React from "react"
import { Button, Form } from "react-bootstrap"
import addbutton from "../../../assets/img/add-button.svg"
import { PatientDialogState, usePatientDialogStore } from "../../../store/patientDailogStore"

const PatientPhotosTab = () => {
	return (
		<React.Fragment>
			<ImageGrid />
		</React.Fragment>
	)
}

export const ImageGrid = () => {
	const [ componentLength, setComponentLength ] = React.useState( [ 1 ] )
	const { setPatientReplaceOrderPhoto } = usePatientDialogStore()

	const handleAddMore = () => {
		setComponentLength( ( prev ) => [ ...prev, prev.length + 1 ] )
	}

	const handleRemove = ( indexToRemove: number ) => {
		setComponentLength( ( prev ) => {
			const newComponentLength = prev.filter( ( _, index ) => index !== indexToRemove )
			setPatientReplaceOrderPhoto( "replace_order_photo_" + indexToRemove, null )
			return newComponentLength
		} )
	}

	return (
		<React.Fragment>
			<section className="d-flex justify-content-center">
				<div>
					<span className="d-grid gap-3 my-2">
						{componentLength.map( ( _, index ) => (
							<CardComponent
								key={index}
								index={index}
								property={"replace_order_photo_" + index}
								onRemove={() => handleRemove( index )}
							/>
						) )}
					</span>
				</div>
			</section>
			<center>
				<Button onClick={handleAddMore}>Add More</Button>
			</center>
		</React.Fragment>
	)
}

const CardComponent = ( {
	property,
	onRemove,
}: {
	index: number
	property: keyof PatientDialogState["replaceOrderPhoto"]
	onRemove: () => void
} ) => {
	const imageRef = React.useRef<HTMLInputElement | null>( null )
	const { replaceOrderPhoto, setPatientReplaceOrderPhoto } = usePatientDialogStore()

	const fileImageSaver = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		e.preventDefault()
		if ( e.currentTarget.files ) {
			setPatientReplaceOrderPhoto( property, e.currentTarget.files[ 0 ] )
		}
	}

	const img = replaceOrderPhoto[ property ]

	return (
		<div>
			<span className="d-flex justify-content-around w-100">
				<Form.Control as="textarea" rows={1} className="mx-5"></Form.Control>
				<Form.Control type="file" accept="image/*" ref={imageRef} onChange={fileImageSaver} />
				{img != null ? (
					<div
						className="card text-bg-secondary mx-5"
						onClick={() => {
							imageRef.current?.click()
						}}
						style={{ width: "5rem" }}
					>
						<img
							alt=""
							src={URL.createObjectURL( img )}
							className="img-fluid"
							style={{ height: "-webkit-fill-available" }}
						/>
					</div>
				) : (
					""
				)}
				<Button onClick={onRemove} variant="danger" className="mx-2">
					Remove
				</Button>
			</span>
		</div>
	)
}

export const Addbutton = () => {
	return (
		<div
			className="card-img-overlay"
			style={{
				display: "flex",
				justifyContent: "end",
				height: "-webkit-fill-available",
				alignItems: "end",
				padding: "6px",
			}}
		>
			<img src={addbutton} alt="" />
		</div>
	)
}

export default PatientPhotosTab
export interface fileResult {
	id: string
	fileType: string
	fileUri: any
	originalFileName: string
	fileCategory: string
	fileSubCategory: string
	patientId: string
	fileTreatmentPlanId: string
	doctorId: string
	createdAt: string
	updatedAt: string
}
