import Moment from "react-moment"
import { useSidebarStore } from "../../../store/sideBarStore"
import { PatientEthenicities } from "../../../utils/appConstants"
import { infoMapper, infoMapperNames } from "../../utlity/GenricFunctions"
import "./sidebar.css"

const SideNavbarInfo = () => {
	const sidebar = useSidebarStore()
	const { patient } = sidebar.sideBarPatientData.PatientData
	const doctorAddress = sidebar.sideBarPatientData
	return (
		<div className="sideBarPatientInfo">
			<div>
				<span className="label">First Name :</span>{" "}
				<span className="label-data">{patient?.firstName}</span>
			</div>
			<div>
				<span className="label">Last Name :</span>{" "}
				<span className="label-data">{patient?.lastName}</span>
			</div>
			<div>
				<span className="label">Birth Date :</span>{" "}
				<span className="label-data">
					<Moment format="MM/DD/YYYY" utc>{patient?.dob}</Moment>
				</span>
			</div>
			<div>
				<span className="label">Gender :</span>{" "}
				<span className="label-data">{patient?.gender}</span>
			</div>
			<div>
				<span className="label">Ethnicity :</span>{" "}
				<span className="label-data">
					{PatientEthenicities[ patient?.ethenicity ] || "Not Specified"}
				</span>
			</div>
			<div>
				<span className="label">Orthodontist :</span>{" "}
				<span className="label-data">
					{infoMapperNames( doctorAddress.PatientData.doctor, [ "id" ] )}
				</span>
			</div>
			<div>
				<span className="label">Office Name :</span>{" "}
				<span className="label-data">
					{doctorAddress.PatientData.treatmentPlanDoctorAddress?.name}
				</span>
			</div>
			<div>
				<span className="label">Office Address :</span>{" "}
				<span className="label-data">
					{infoMapper( doctorAddress.PatientData.treatmentPlanDoctorAddress, [ "id" ] )}
				</span>
			</div>
		</div>
	)
}

export default SideNavbarInfo
