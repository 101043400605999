import React from "react"
import {
	Button,
	Form,
	FormCheckProps,
	FormControlProps,
	FormSelectProps,
	Spinner,
} from "react-bootstrap"
import { FieldPath, get, useFormContext } from "react-hook-form"
import "../../../assets/styles/pages/Theme.form.css"
import {
	ResponseTypeRole,
	RoleCreationSchemaType,
	useListRolesQuery,
} from "../../../services/CelebRole.Service"
import { CreateUserType } from "../../../services/CelebUser.Service"

export const BoundControl = ( { name, label, required, ...props }: BoundControlProps ) => {
	const methods = useFormContext<CreateUserType>()
	return (
		<Form.Group>
			{typeof label === "string" && (
				<Form.Label>
					{label}
					{required && <span style={{ color: "red" }}>*</span>}
				</Form.Label>
			)}
			<Form.Control
				size="sm"
				{...methods.register( name )}
				{...props}
				isInvalid={get( methods.formState.errors, name )}
			/>
			<Form.Control.Feedback type="invalid">
				{get( methods.formState.errors, name )?.message}
			</Form.Control.Feedback>
		</Form.Group>
	)
}

export type BoundControlProps = Omit<FormControlProps, "name"> & {
	name: FieldPath<CreateUserType>
	label?: string
	required?: boolean
}

export const BoundControlPricing = ( { name, label, required, ...props }: BoundControlProps ) => {
	const methods = useFormContext<CreateUserType>()
	return (
		<Form.Group>
			<div className="input-group mb-3">
				<div className="input-group-prepend">
					<span className="input-group-text">$</span>
				</div>
				{typeof label === "string" && (
					<Form.Label>
						{label}
						{required && <span style={{ color: "red" }}>*</span>}
					</Form.Label>
				)}
				<Form.Control
					size="sm"
					type="number"
					step=".01"
					{...methods.register( name, { valueAsNumber: true } )}
					{...props}
					isInvalid={get( methods.formState.errors, name )}
				/>
			</div>
			<Form.Control.Feedback type="invalid">
				{get( methods.formState.errors, name )?.message}
			</Form.Control.Feedback>
		</Form.Group>
	)
}

export type BoundControlPricingProps = Omit<FormControlProps, "name"> & {
	name: FieldPath<CreateUserType>
	label?: string
	required?: boolean
}

export const BoundSelect = ( {
	name,
	label,
	required,
	options,
	placeholder,
	...props
}: BountSelectProps ) => {
	const methods = useFormContext<CreateUserType>()
	const fieldProps = methods.register( name )

	return (
		<Form.Group>
			{typeof label === "string" && (
				<Form.Label>
					{label}
					{required && <span style={{ color: "red" }}>*</span>}
				</Form.Label>
			)}
			<Form.Select
				size="sm"
				{...fieldProps}
				{...props}
				isInvalid={get( methods.formState.errors, name )}
			>
				{typeof placeholder === "string" && (
					<option value="" disabled>
						{placeholder}
					</option>
				)}
				{options.map( ( opt ) => (
					<option key={opt.id} value={opt.id}>
						{opt.label}
					</option>
				) )}
			</Form.Select>
			<Form.Control.Feedback type="invalid">
				{get( methods.formState.errors, name )?.message}
			</Form.Control.Feedback>
		</Form.Group>
	)
}

export type BountSelectProps = Omit<FormSelectProps, "name" | "onSelect"> & {
	name: keyof CreateUserType
	options: SelectOption[]
	placeholder?: string
	label?: string
	required?: boolean
}

export type SelectOption = { id: string; label: string; onSelect?: ( value: string ) => void }

export const BoundCheckbox = ( { name, ...props }: BoundCheckboxProps ) => {
	const methods = useFormContext<CreateUserType>()
	return (
		<Form.Check
			type="checkbox"
			{...methods.register( name )}
			{...props}
			isInvalid={get( methods.formState.errors, name )}
		/>
	)
}

export type BoundCheckboxProps = Omit<FormCheckProps, "name"> & {
	name: keyof CreateUserType
	label?: string
	required?: boolean
}

export const Submit = () => {
	const methods = useFormContext<CreateUserType>()
	return (
		<Button size="sm" type="submit" className="mt-4 form-button">
			{methods.formState.isSubmitting ? (
				<>
					<Spinner animation="border" role="status" size="sm" /> Saving..{" "}
				</>
			) : (
				"Save Account type"
			)}
		</Button>
	)
}

export const DoctorSpecificInput: React.FC<React.PropsWithChildren> = ( { children } ) => {
	const methods = useFormContext<CreateUserType>()
	const {
		data: { data: roles },
	} = useListRolesQuery()
	const roleId = methods.watch( "roleId" )
	const selectedRole = roles.find( ( role: ResponseTypeRole ) => role.id === roleId )

	if ( selectedRole?.specialRole == "doctor" ) {
		return children
	}

	return null
}

export const BoundControlTypes = ( { name, label, required, ...props }: BoundControlTypeProps ) => {
	const methods = useFormContext<RoleCreationSchemaType>()

	return (
		<Form.Group>
			{typeof label === "string" && (
				<Form.Label>
					{label}
					{required && <span style={{ color: "red" }}>*</span>}
				</Form.Label>
			)}
			<Form.Control
				size="sm"
				{...methods.register( name )}
				{...props}
				isInvalid={get( methods.formState.errors, name )}
			/>
			<Form.Control.Feedback type="invalid">
				{get( methods.formState.errors, name )?.message}
			</Form.Control.Feedback>
		</Form.Group>
	)
}

export type BoundControlTypeProps = Omit<FormControlProps, "name"> & {
	name: FieldPath<RoleCreationSchemaType>
	label?: string
	required?: boolean
}
