import { useSuspenseQuery } from "@tanstack/react-query"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"

export class CelebTreatmentFilesService {
	static _instance: CelebTreatmentFilesService = new CelebTreatmentFilesService()

	private constructor() {
		CelebTreatmentFilesService._instance = this
	}

	static getInstance(): CelebTreatmentFilesService {
		return CelebTreatmentFilesService._instance
	}

	async getByTreatment( treatmentId: string | null ) {
		const requestUrl = `${constants.TREATMENT_FILES}/treatment/${treatmentId}`

		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}
}
export const getPatientTreatmentFilesQuery = ( treatmentId: string | null ) => {
	return useSuspenseQuery( {
		queryKey: [ "PatientDataFiles", treatmentId ],
		queryFn: () => {
			return CelebTreatmentFilesService.getInstance()
				.getByTreatment( treatmentId )
				.then( ( res ) => res.data.data )
				.then( ( res ) => res )
		},
	} )
}
