import { ButtonGroup, Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap"
import { Link, Params, useNavigate, useParams, useSearchParams } from "react-router-dom"
import {
	CategoriesResponseType,
	CategoriesType,
	useListAllServicesQuery,
} from "../../services/CelebService.Service"
import { usePatientDialogStore } from "../../store/patientDailogStore"
import { useTaskCompletionDialogStore } from "../../store/taskCompletionDialogStore"
import { userSessionStore } from "../../store/userSessionStore"
import { TaskCategory } from "../../utils/appConstants"
import SearchInput from "../form/SearchInput"
import {
	CheckAdmin,
	CheckDoctor,
	CheckGenericPermission,
	CheckOperator,
} from "../permissions/CheckPermision"

const SubNavBar = () => {
	const paramsData: Readonly<Params<string>> = useParams()
	const userSession = userSessionStore()
	const [ searchParams, setSearchParams ] = useSearchParams()
	const taskCompletionStore = useTaskCompletionDialogStore()
	const patientStore = usePatientDialogStore()
	const { data: categoriesData } = useListAllServicesQuery()
	const navigate = useNavigate()
	const providerCategories = categoriesData.services.find(
		( category: CategoriesResponseType ) => paramsData.id === category.id,
	)

	const openCompleteTask = () => {
		taskCompletionStore.landingPageTasks.length >= 1
			? taskCompletionStore.setDialogOpen( true )
			: null
	}

	const setSearch = ( search: string ) => {
		const newParams = new URLSearchParams( searchParams )
		newParams.set( "searchPatient", search )
		setSearchParams( newParams )
		if ( search.length == 0 ) {
			const newParameter = new URLSearchParams( window.location.search )
			newParameter.delete( "searchPatient" )
			const newUrl = `${window.location.pathname}?${newParameter.toString()}`
			return navigate( newUrl )
		}
	}
	return (
		<nav style={{ backgroundColor: "#d9d9d9" }}>
			<div className="d-flex justify-content-between align-items-center p-2">
				<SearchInput callback={( search: string ) => setSearch( search )} />
				<span className="text-end">
					<CheckGenericPermission genericLabel={"Task_Completion"}>
						<a href="#TaskCompletion" className="btn btn-primary me-2" onClick={openCompleteTask}>
							Complete task
						</a>
					</CheckGenericPermission>
					<CheckGenericPermission genericLabel={"Patient_Create"}>
						<a
							href="#PatientInfo"
							className="btn btn-primary me-2"
							onClick={() => {
								patientStore.setCreateOpen( true )
							}}
						>
							Create New Patient
						</a>
					</CheckGenericPermission>
				</span>
			</div>
			<Navbar expand="lg" className="p-0">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<div className="w-100 text-start" style={{ backgroundColor: "#d9d9d9" }}>
						<div className="w-100">
							<Nav
								variant="tabs"
								className="w-100 m-0 p-0"
								defaultActiveKey={"?status=active"}
								style={{ border: "none" }}
							>
								<CheckAdmin>
									<Dropdown
										title="All"
										id="basic-nav-dropdown"
										as={ButtonGroup}
										className="border-0"
									>
										<Nav.Link
											eventKey={"?status=active"}
											as={Link}
											to={"?status=active"}
											onClick={() => userSession.setCategory( { type: "", id: "" } )}
										>
											All
										</Nav.Link>
										<Dropdown.Toggle split variant="Secondary" id="dropdown-split-basic" />
										<Dropdown.Menu>
											{providerCategories?.categories.map( ( cat: CategoriesType ) => {
												if ( cat.type == TaskCategory.OTHERS ) return null
												return (
													<NavDropdown.Item
														className="btn border-0"
														as={Link}
														to={"?" + cat.type + "=" + cat.id}
														onClick={() =>
															userSession.setCategory( {
																type: cat.type,
																id: cat.id,
																path: "me",
																isAll: true,
																taskAssignedRole: "othr",
															} )
														}
													>
														{cat.name}
													</NavDropdown.Item>
												)
											} )}
										</Dropdown.Menu>
									</Dropdown>
								</CheckAdmin>

								<CheckOperator>
									<Dropdown
										title="All"
										id="basic-nav-dropdown"
										as={ButtonGroup}
										className="border-0"
									>
										<Nav.Link
											eventKey={"?status=active"}
											as={Link}
											to={"?status=active"}
											onClick={() =>
												userSession.setCategory( {
													type: "",
													id: "",
													isAll: true,
													taskAssignedRole: "othr",
												} )
											}
										>
											Action Needed
										</Nav.Link>
										<Dropdown.Toggle split variant="Secondary" id="dropdown-split-basic" />
										<Dropdown.Menu>
											{providerCategories?.categories.map( ( cat: CategoriesType ) => {
												if ( cat.type == TaskCategory.OTHERS ) return null
												return (
													<NavDropdown.Item
														className="btn border-0"
														as={Link}
														to={"?" + cat.type + "=" + cat.id}
														onClick={() =>
															userSession.setCategory( {
																type: cat.type,
																id: cat.id,
																path: "me",
																isAll: true,
																taskAssignedRole: "othr",
															} )
														}
													>
														{cat.name}
													</NavDropdown.Item>
												)
											} )}
										</Dropdown.Menu>
									</Dropdown>
								</CheckOperator>

								<CheckDoctor>
									<Nav.Link
										className="nav-link"
										eventKey={"?status=active"}
										as={Link}
										to={"?status=active"}
										onClick={() => {
											userSession.setCategory( {
												id: "",
												type: "",
												path: "me",
												isAll: true,
												taskAssignedRole: "doc",
											} )
										}}
									>
										Action Needed
									</Nav.Link>
								</CheckDoctor>

								<CheckOperator>
									<Nav.Link
										className="nav-link"
										eventKey={"?status=AwaitingDoctor"}
										as={Link}
										to={"?status=active"}
										onClick={() => {
											userSession.setCategory( {
												type: "",
												id: "",
												path: "awaiting",
												isAll: true,
												taskAssignedRole: "doc",
											} )
										}}
									>
										Awaiting Doctor
									</Nav.Link>
								</CheckOperator>

								<Nav.Link
									className="nav-link"
									eventKey={"?status=paused"}
									as={Link}
									to="?status=paused"
								>
									Pause
								</Nav.Link>

								<Nav.Link
									className="nav-link"
									eventKey={"?status=completed"}
									as={Link}
									to={"?status=completed"}
								>
									Shipped
								</Nav.Link>

								<Nav.Link
									className="nav-link"
									eventKey={"?status=archived"}
									as={Link}
									to={"?status=archived"}
								>
									Archived
								</Nav.Link>

								<CheckDoctor>
									{providerCategories?.categories.map( ( cat: CategoriesType ) => {
										if ( cat.type !== "consume" ) return null
										return (
											<Nav.Link
												className="nav-link"
												eventKey={"?" + cat.type + "=" + cat.id}
												as={Link}
												to={"?" + cat.type + "=" + cat.id}
												onClick={() => {
													setSearchParams( "?" + cat.type + "=" + cat.id )
													userSession.setCategory( {
														type: cat.type,
														id: cat.id,
														path: "awaiting",
														isAll: true,
														taskAssignedRole: "othr",
													} )
												}}
											>
												{cat.name}
											</Nav.Link>
										)
									} )}
								</CheckDoctor>
							</Nav>
						</div>
					</div>
				</Navbar.Collapse>
			</Navbar>
		</nav>
	)
}

export default SubNavBar
