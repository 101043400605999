import {
	QueryClient,
	QueryClientProvider as TanStackQueryClientProvider,
} from "@tanstack/react-query"

export const queryClient = new QueryClient( {
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: process.env.NODE_ENV === "development",
			refetchOnMount: "always",
			retry: false,
		},
	},
} )

export const QueryClientProvider: React.FC<React.PropsWithChildren> = ( props ) => (
	<TanStackQueryClientProvider client={queryClient} {...props} />
)
