import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { useSetupVerifiedMutation } from "../../../services/CelebPayments.Service"
import { useProfileDialogStore } from "../../../store/profileDialogStore"

const PaymentMethodForm = ( { clientSecret }: { clientSecret: string } ) => {
	const stripe = useStripe()
	const profile = useProfileDialogStore()
	const elements = useElements()
	const [ errorMessage, setErrorMessage ] = useState<string | null>( null )
	const [ isProcessing, setIsProcessing ] = useState( false )
	const [ isSuccess, setIsSuccess ] = useState( false )
	const { mutate: notifySetupVerified } = useSetupVerifiedMutation()
	const handleSubmit = async ( event: React.FormEvent ) => {
		event.preventDefault()
		if ( !stripe || !elements ) {
			return
		}

		setIsProcessing( true )
		await elements?.submit()
		const { error } = await stripe.confirmSetup( {
			elements,
			clientSecret: clientSecret,
			confirmParams: {
				return_url: "",
			},
			redirect: "if_required",
		} )

		if ( error ) {
			// Show error to your customer (for example, insufficient funds)
			setErrorMessage( error.message ?? "An error occurred" )
			setIsProcessing( false )
		} else {
			setupintent()
			// Payment method setup succeeded
			setIsSuccess( true )
		}

		setIsProcessing( false )
	}

	const setupintent = () => {
		if ( !stripe || !elements ) {
			return
		}
		stripe.retrieveSetupIntent( clientSecret ).then( ( { setupIntent } ) => {
			if ( !setupIntent ) {
				return
			}
			notifySetupVerified()
			setTimeout( () => {
				profile.setPaymentCreateOpen( false )
			}, 3000 )
		} )
	}
	const paymentElementOptions = {
		layout: "tabs",
	}
	return (
		<form onSubmit={handleSubmit}>
			<PaymentElement id="payment-element" options={{ layout: "tabs" }} />
			<Button type="submit" disabled={!stripe || isProcessing}>
				{isProcessing ? "Processing..." : "Save Payment Method"}
			</Button>
			{errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
			{isSuccess && <div style={{ color: "green" }}>Payment method setup successful!</div>}
		</form>
	)
}

export default PaymentMethodForm
