import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { Container, Modal } from "react-bootstrap"
import { FormProvider, useForm } from "react-hook-form"
import { useLocation } from "react-router"
import { ReplaceOrderSchema, ReplaceOrderSchemaType } from "../../../services/CelebPatient.Service"
import { getPatientByIdQuery } from "../../../services/CelebTreatment.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import { useTeethStore } from "../../../store/toothSelectionStore"
import { clearHash } from "../../utlity/GenricFunctions"
import { photosLibraryReset } from "../editPatients-v2/defaultFileShowCase"
import PatientCbctTab from "./PatientCBCT.tab"
import PatientInfoTab from "./PatientInfo.tab"
import PatientNavbar from "./PatientNavbar"
import PatientPhotosTab from "./PatientPhotos.tab"
import PatientScanTab from "./PatientScan.tab"
import PatientXrayTab from "./PatientXray.tab"
import PatientTreatmentInstructionTab from "./ReplacementInstructions.tab"

const PatientReplaceDialog = () => {
	const PatientDialog = usePatientDialogStore()
	const teethStore = useTeethStore()
	const clearHashClear = clearHash()
	const { data: defaultValuesEdit } = getPatientByIdQuery( PatientDialog.replaceOpen )
	const { toggleToothBondingJsonFile, toggleToothExtractionJsonFile, resetTeethSelection } =
		useTeethStore()
	const methods = useForm<ReplaceOrderSchemaType>( {
		resolver: zodResolver( ReplaceOrderSchema ),
		mode: "all",
		defaultValues: {
			...defaultValuesEdit.patient,
			...defaultValuesEdit,
		},
	} )

	React.useEffect( () => {
		toggleToothBondingJsonFile( defaultValuesEdit.teethBonding )
		toggleToothExtractionJsonFile( defaultValuesEdit.teethExtraction )
	}, [] )

	const show = PatientDialog.replaceOpen == null ? false : true

	const clearFormData = () => {
		methods.reset()
		teethStore.resetTeethSelection()
		PatientDialog.clearState()
		clearHashClear()
		PatientDialog.setReplaceOpen( null )
		photosLibraryReset()
	}
	return (
		<React.Fragment>
			<FormProvider {...methods}>
				<Modal show={show} onHide={clearFormData} size="xl" backdrop="static">
					<PatientNavbar />
					<PatientAddImpl />
				</Modal>
			</FormProvider>
		</React.Fragment>
	)
}

const PatientAddImpl = () => {
	return (
		<React.Fragment>
			<Container fluid>
				<ActiveTabExporter />
			</Container>
		</React.Fragment>
	)
}

const ActiveTabExporter = () => {
	const location = useLocation()

	switch ( location.hash ) {
		case "#PatientInfo":
			return <PatientInfoTab />
		case "#Scan":
			return <PatientScanTab />
		case "#Replace":
			return <PatientTreatmentInstructionTab />
		case "#Photos":
			return <PatientPhotosTab />
		case "#X-rays":
			return <PatientXrayTab />
		case "#CBCT":
			return <PatientCbctTab />
		default:
			return <PatientInfoTab />
	}
}
export default PatientReplaceDialog
